import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Congreso_modelo_response, Lista_catalogo_congreso_response, Lista_congresos_response } from 'src/model/Catalogos/Catalogos';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-congresos',
  templateUrl: './congresos.component.html',
  styleUrls: ['./congresos.component.css'],  
  providers: [ServiceGenerico]
})
export class CongresosComponent implements OnInit {
  @ViewChild("modalAddCongreso", { static: false }) modalAddCongreso: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarCongreso: NgbModalRef;
  modalEliminarCongreso: NgbModalRef;

  formGroupAgregarCongresos: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  listaCongresos: Lista_congresos_response[];
  formGroupCongreso: FormGroup;
  _idCongresoEliminar: any;
  _nombreCongreso: any;
  _bndCongresoSelected: boolean = false;
  Congreso: Congreso_modelo_response;
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormAgregarCongresos();
    this.obtenerCongresos();
    this.titulo = "Congresos";
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerCongresos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoCongreso?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCongresos = [] = tempdate.response as Lista_congresos_response[];
            this.renderTabla();
          } else {
            this.listaCongresos = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarCongresos() {
    this.formGroupAgregarCongresos = this.fb.group({
      p_IDCongreso: [0], 
      p_Nombre: ["", [Validators.required]], 
      p_Descripcion: [""], 
      p_FechaInicial: ["", [Validators.required]], 
      p_FechaFinal: ["", [Validators.required]], 
      p_NumeroCongreso: [""], 
      p_Anio: ["",[Validators.required, Validators.pattern(/^[0-9]*$/)]], 
      p_InfoOrganizadores: [""],
      p_Lugar: ["", [Validators.required]]
    });

  }


  agregarCongreso() {
    this.funcionalidad = 'Nuevo';
    this.openModalFormCongreso(true);
  }

  GuardarCongreso() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarCongresos.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostCongreso")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCongresos();
            this.modalAgregarCongreso.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutCongreso")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCongresos();
              this.modalAgregarCongreso.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  editarverDetalleCongreso(idCongreso, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormCongreso(false);
    } else {
      this.funcionalidad = 'Editar';
      this.openModalFormCongreso(true);
      this.esEditable = true;
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCongreso_x_id?p_IDCongreso=" + idCongreso)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Congreso = tempdate.response as Congreso_modelo_response;
            this.formGroupAgregarCongresos.get('p_IDCongreso').setValue(this.Congreso[0].idCongreso);
            this.formGroupAgregarCongresos.get('p_Nombre').setValue(this.Congreso[0].nombre);
            this.formGroupAgregarCongresos.get('p_Descripcion').setValue(this.Congreso[0].descripcion);
            this.formGroupAgregarCongresos.get('p_NumeroCongreso').setValue(this.Congreso[0].numeroCongreso);
            this.formGroupAgregarCongresos.get('p_Anio').setValue(this.Congreso[0].anio);
            this.formGroupAgregarCongresos.get('p_InfoOrganizadores').setValue(this.Congreso[0].infoOrganizadores);
            this.formGroupAgregarCongresos.get('p_Lugar').setValue(this.Congreso[0].lugar);
       
            const fechaCostoInicio = new Date(this.Congreso[0].fechaInicial);
            const fechaCortaI = fechaCostoInicio.toISOString().split('T')[0];
            this.formGroupAgregarCongresos.get('p_FechaInicial').setValue(fechaCortaI);
            const fechaCostoFin = new Date(this.Congreso[0].fechaFinal);
            const fechaCortaF = fechaCostoFin.toISOString().split('T')[0];
            this.formGroupAgregarCongresos.get('p_FechaFinal').setValue(fechaCortaF);

          } else {
            this.listaCongresos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  // eliminarCongreso(_idCongresoEliminar) {
  //   this.operacionRespuesta.EstaEjecutando = true;
  //   this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteCongreso?p_IDCongreso=" + _idCongresoEliminar)
  //     .subscribe(
  //       (tempdate) => {
  //         if (tempdate.response[0].success == 1) {
  //           this.openMensajes(tempdate.response[0].msg, false, '');
  //           this.obtenerCongresos();
  //           this.modalEliminarCongreso.close();
  //         } else {
  //           this.openMensajes(tempdate.response[0].msg, true, '');
  //         }
  //         this.operacionRespuesta.EstaEjecutando = false;
  //       },
  //       async () => {
  //         this.openMensajes("No se pudo realizar la acción", true, '');
  //         this.operacionRespuesta.EstaEjecutando = false;
  //       }
  //     );
  // }

  openModalFormCongreso(esEditable: boolean) {
    this.esEditable = esEditable;
   
    this.formGroupAgregarCongresos.reset({ 
      p_IDCongreso: 0, 
      p_Nombre: "", 
      p_Descripcion: "", 
      p_FechaInicial: "", 
      p_FechaFinal: "", 
      p_NumeroCongreso: "", 
      p_InfoOrganizadores: "",
      p_Anio: "", 
      p_Lugar: ""
    });
    this.modalAgregarCongreso = this.modalService.open(this.modalAddCongreso, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  // openDesactivar(idCongreso, nombreCongreso) {
  //   this._idCongresoEliminar = idCongreso;
  //   this._nombreCongreso = nombreCongreso;
  //   this.modalEliminarCongreso = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  // }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Congreso';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  }
