import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login/login.component';
import { MenuComponent } from './menu/menu.component';
import { AppRoutingModule } from './app.routing';
import { AuthGuard } from "../app/guards/AuthGuard";
import { JwtModule } from '@auth0/angular-jwt';
import { ModuloModalMensajeComponent } from './shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { ModuloModalAdvertenciaComponent } from './shared/modulo-modal-advertencia/modulo-modal-advertencia.component';
import { AuthService } from 'src/services/auth.service';
import { ThemeConstants } from './@espire/shared/config/theme-constant';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModuloSpinerLoginComponent } from './shared/modulo-spiner-login/modulo-spiner-login.component';
import { ModuloMensajesComponent } from './shared/modulo-mensajes/modulo-mensajes.component';
import { BodyComponent } from './body/body.component';
import { CategoriasComponent } from './catalogos/categorias/categorias.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ModuloCambioContrasenaComponent } from './login/modulo-cambio-contrasena/modulo-cambio-contrasena.component';
import { registerLocaleData } from '@angular/common';
import localeEsMX from '@angular/common/locales/es-MX'; // Importa los datos de localización para es-MX
import { CongresistasComponent } from './congresistas/congresistas.component';
import { RemesasComponent } from './remesas/remesas.component';
import { GenericosComponent } from './catalogos/genericos/genericos.component';
import { PlanesComponent } from './catalogos/planes/planes.component';
import { GruposComponent } from './catalogos/grupos/grupos.component';
import { PerfilesComponent } from './catalogos/perfiles/perfiles.component';
import { CongresosComponent } from './catalogos/congresos/congresos.component';
import { ZonasComponent } from './catalogos/zonas/zonas.component';
import { HotelesComponent } from './catalogos/hoteles/hoteles.component';
import { PagosComponent } from './remesas/pagos/pagos.component';
import { AsignarComponent } from './asignar/asignar.component';
import { OrdenesComponent } from './ordenes/ordenes.component';
import { ConfiguracionComponent } from './catalogos/configuracion/configuracion.component';

/* jwt getToken */
export function obtenerToken(): string {
  return localStorage.getItem("jwt");
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    BodyComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    MenuComponent,
    ModuloSpinerLoginComponent,
    ModuloModalMensajeComponent,
    ModuloModalAdvertenciaComponent,
    ModuloMensajesComponent,
    HomeComponent,
    CategoriasComponent,
    UsuariosComponent,
    ModuloCambioContrasenaComponent,
    CongresistasComponent,
    RemesasComponent,
    GenericosComponent,
    PlanesComponent,
    GruposComponent, 
    PerfilesComponent,
    CongresosComponent,
    ZonasComponent,
    HotelesComponent,
    PagosComponent,
    AsignarComponent,
    OrdenesComponent,
    ConfiguracionComponent
     ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: obtenerToken,
        // whitelistedDomains: ["localhost:44394"],
        // whitelistedDomains: ["api.chbvreunion.com"],
        whitelistedDomains: ["congreso.api.duranm.me"],
      },
    }),
  ],
  entryComponents: [
    ModuloModalMensajeComponent
  ],
  providers: [AuthGuard, ThemeConstants, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeEsMX, 'es-MX');

  }
 }

