import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_generico_response, Lista_catalogo_grupo_response } from 'src/model/Catalogos/Catalogos';
import { Lista_congresista_sistema_modelo_response, Usuario_congresista_modelo_response, Usuario_congresista_pdf_modelo_response } from 'src/model/Catalogos/Congresistas';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { AuthIdentity } from '../guards/AuthIdentity';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-congresistas',
  templateUrl: './congresistas.component.html',
  styleUrls: ['./congresistas.component.css'],
  providers: [ServiceGenerico]
})
export class CongresistasComponent implements OnInit {
  @ViewChild("modalAddCongresista", { static: false }) modalAddCongresista: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  @ViewChild("contentCongresistaPDF", { static: false }) ModalCongresistaPDF: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarCongresista: NgbModalRef;
  modalEliminarCongresista: NgbModalRef;
  modalCongresistaPDF: NgbModalRef;

  formGroupAgregarCongresista: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  listaCongresistas: Lista_congresista_sistema_modelo_response[];
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  private _nombreUsuario: any;
  Congresista: Usuario_congresista_modelo_response;
  titulo: string;
  listaTiempoMilitancia: Lista_catalogo_generico_response[];
  listaGenero: Lista_catalogo_generico_response[];
  listaOrientacionSexual: Lista_catalogo_generico_response[];
  listaDiscapacidad: Lista_catalogo_generico_response[];
  listaGrupos: Lista_catalogo_grupo_response[];
  bnd_p_LlevaServicio: boolean = false;
  bnd_p_ParejaEnElMovimiento: boolean = false;
  bnd_p_TieneDiscapacidad: boolean = false;
  idUsuario: number;
  _idContactoEliminar: number;
  listaServicios: Lista_catalogo_generico_response[];
  CongresistaPdf: Usuario_congresista_pdf_modelo_response;
  listaGruposPareja: Lista_catalogo_grupo_response[];

  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
    this.idUsuario = usuarioActual.IDUsuario;
    this.inicializaTabla();
    this.obtenerCongresista();
    this.inicializarFormAgregarCongresista();
    this.obtenerCatalogoGenerico(1); //Tiempo de militancia
    this.obtenerCatalogoGenerico(2); //Género
    this.obtenerCatalogoGenerico(3); //Tendencia sexual
    this.obtenerCatalogoGenerico(4); //Discapacidad
    this.obtenerCatalogoGenerico(6); //Servicio
    this.obtenerCatalogoGrupo(); 
    this.obtenerCatalogoGrupoPareja(); 
    this.titulo = 'Congresistas'
    
 
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerCongresista() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Congresistas/GetListaCongresistas?p_estado=1&p_IDUsuario="+this.idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCongresistas = [] = tempdate.response as Lista_congresista_sistema_modelo_response[];
            this.renderTabla();
          } else {
            this.listaCongresistas = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  inicializarFormAgregarCongresista() {
    this.formGroupAgregarCongresista = this.fb.group({
      p_opcion: [0], 
      p_Grupo: [0], 
      p_IDContacto: [0], 
      p_Nombre: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]], 
      p_aPaterno: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/)]], 
      p_aMaterno: ["", [Validators.pattern(/^[a-zA-Z ]*$/)]],
      p_Alias: ["", [Validators.pattern(/^[a-zA-Z ]*$/)]],
      p_Militancia: [0],
      p_Tel1: ["", [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      p_Email1: ["", [Validators.email]],
      p_FNac: [""],
      p_Edad: [0],
      p_Genero: [0],
      p_Orientacion: [0],
      p_TelGrupo: ["",[Validators.pattern(/^[0-9]*$/)]],
      p_PrimerCongreso: [2],
      p_ResponsableGrupo: [2],
      p_LlevaServicio: [2],
      p_Servicio: [0],
      p_EstaEnCasaHogar: [2],
      p_ParejaEnElMovimiento: [2],
      p_HospedarseConPareja: [2],
      p_ContactoParejaNombre: ["",[Validators.pattern(/^[a-zA-Z ]*$/)]],
      p_ContactoParejaPaterno: ["",[Validators.pattern(/^[a-zA-Z ]*$/)]],
      p_ContactoParejaMaterno: ["",[Validators.pattern(/^[a-zA-Z ]*$/)]],
      p_GrupoPareja: [0],
      p_TieneDiscapacidad: [2],
      p_Discapacidad: [0],
      p_ContactoCDiscapNombre: ["",[Validators.pattern(/^[a-zA-Z ]*$/)]],
      p_ContactoCDiscapPaterno: ["",[Validators.pattern(/^[a-zA-Z ]*$/)]],
      p_ContactoCDiscapMaterno: ["",[Validators.pattern(/^[a-zA-Z ]*$/)]],
      p_IDGrupoCDiscap: [0],
      p_ContactoEmergencia: [""],
      p_TelefonoContactoEmer: ["", [Validators.pattern(/^[0-9]*$/)]],
      p_IDUsuario: [0]
    });

  }
  agregarCongresista() {
    this.funcionalidad = 'Nuevo';
    this.openModalFormUsuario(true);
  }
  GuardarCongresista() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarCongresista.value;
    model.p_IDUsuario = this.idUsuario;
    
    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Congresistas/PostCongresista")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCongresista();
              this.modalAgregarCongresista.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Congresistas/PutCongresista")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCongresista();
              this.modalAgregarCongresista.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }
  editarverDetalleCongresista(idContacto, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormUsuario(false);
    } else {
      this.funcionalidad = 'Editar';
      this.openModalFormUsuario(true);
      this.esEditable = true;
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Congresistas/GetCongresista_x_id?p_IDContacto=" + idContacto)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Congresista = tempdate.response as Usuario_congresista_modelo_response;
            this.formGroupAgregarCongresista.get('p_Grupo').setValue(this.Congresista[0].idGrupo);
            this.formGroupAgregarCongresista.get('p_IDContacto').setValue(this.Congresista[0].idContacto);
            this.formGroupAgregarCongresista.get('p_Nombre').setValue(this.Congresista[0].nombre);
            this.formGroupAgregarCongresista.get('p_aPaterno').setValue(this.Congresista[0].aPaterno);
            this.formGroupAgregarCongresista.get('p_aMaterno').setValue(this.Congresista[0].aMaterno);
            this.formGroupAgregarCongresista.get('p_Alias').setValue(this.Congresista[0].alias);
            this.formGroupAgregarCongresista.get('p_Militancia').setValue(this.Congresista[0].tiempoMilitancia);
            this.formGroupAgregarCongresista.get('p_Tel1').setValue(this.Congresista[0].tel1);
            this.formGroupAgregarCongresista.get('p_Email1').setValue(this.Congresista[0].email1);
            this.formGroupAgregarCongresista.get('p_Genero').setValue(this.Congresista[0].genero);
            this.formGroupAgregarCongresista.get('p_Orientacion').setValue(this.Congresista[0].orientacion);
            this.formGroupAgregarCongresista.get('p_TelGrupo').setValue(this.Congresista[0].telGrupo);
            this.formGroupAgregarCongresista.get('p_PrimerCongreso').setValue(this.Congresista[0].primerCongreso ? 1 : 0);
            this.formGroupAgregarCongresista.get('p_ResponsableGrupo').setValue(this.Congresista[0].responsableGrupo ? 1 : 0);
            this.formGroupAgregarCongresista.get('p_LlevaServicio').setValue(this.Congresista[0].llevaServicio ? 1 : 0);
            this.formGroupAgregarCongresista.get('p_Servicio').setValue(this.Congresista[0].servicio);
            this.formGroupAgregarCongresista.get('p_EstaEnCasaHogar').setValue(this.Congresista[0].estaEnCasaHogar ? 1 : 0);
            this.formGroupAgregarCongresista.get('p_ParejaEnElMovimiento').setValue(this.Congresista[0].parejaEnElMovimiento ? 1 : 0);
            this.formGroupAgregarCongresista.get('p_HospedarseConPareja').setValue(this.Congresista[0].hospedarseConPareja ? 1 : 0);
            this.formGroupAgregarCongresista.get('p_ContactoParejaNombre').setValue(this.Congresista[0].contactoParejaNombre);
            this.formGroupAgregarCongresista.get('p_ContactoParejaPaterno').setValue(this.Congresista[0].contactoParejaPaterno);
            this.formGroupAgregarCongresista.get('p_ContactoParejaMaterno').setValue(this.Congresista[0].contactoParejaMaterno);
            this.formGroupAgregarCongresista.get('p_GrupoPareja').setValue(this.Congresista[0].idGrupoPareja);
            this.formGroupAgregarCongresista.get('p_TieneDiscapacidad').setValue(this.Congresista[0].discapacidad == 0 ? 0 : 1);
            this.formGroupAgregarCongresista.get('p_Discapacidad').setValue(this.Congresista[0].discapacidad);
            this.formGroupAgregarCongresista.get('p_ContactoCDiscapNombre').setValue(this.Congresista[0].contactoCDiscapNombre);
            this.formGroupAgregarCongresista.get('p_ContactoCDiscapPaterno').setValue(this.Congresista[0].contactoCDiscapPaterno);
            this.formGroupAgregarCongresista.get('p_ContactoCDiscapMaterno').setValue(this.Congresista[0].contactoCDiscapMaterno);
            this.formGroupAgregarCongresista.get('p_IDGrupoCDiscap').setValue(this.Congresista[0].idGrupoCDiscap);
            this.formGroupAgregarCongresista.get('p_ContactoEmergencia').setValue(this.Congresista[0].contactoEmergencia);
            this.formGroupAgregarCongresista.get('p_TelefonoContactoEmer').setValue(this.Congresista[0].telefonoContactoEmer);
       
            const fechaNacimiento = new Date(this.Congresista[0].fechaNacimiento);
            const fechaCorta = fechaNacimiento.toISOString().split('T')[0];
            this.formGroupAgregarCongresista.get('p_FNac').setValue(fechaCorta);

            this.calculaEdad();
            this.habilitaCampoDependencia('p_LlevaServicio');
            this.habilitaCampoDependencia('p_ParejaEnElMovimiento');
            this.habilitaCampoDependencia('p_TieneDiscapacidad');

          } else {
            this.listaCongresistas = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

  }
  eliminarCongresista(_idContactoEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Congresistas/DeleteCongresista?p_IDContacto=" + _idContactoEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCongresista();
            this.modalEliminarCongresista.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  openModalFormUsuario(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarCongresista.reset({ p_opcion:0, p_IDContacto:0, p_Grupo: 0, p_Nombre: '', p_aPaterno: '', p_aMaterno: '', p_Alias: '', p_Militancia: 0,
    p_Tel1: '', p_Email1: '', p_FNac: '', p_Edad: 0, p_Genero: 0, p_Orientacion: 0, p_TelGrupo: '', p_PrimerCongreso: 2,
    p_ResponsableGrupo: 2, p_LlevaServicio: 2, p_Servicio: 0, p_EstaEnCasaHogar: 2, p_ParejaEnElMovimiento: 2, p_HospedarseConPareja: 2, 
    p_ContactoParejaNombre: '', p_ContactoParejaPaterno: '',p_ContactoParejaMaterno: '', p_GrupoPareja: 0,
    p_TieneDiscapacidad: 2, p_Discapacidad: 0, p_ContactoCDiscapNombre: '',  p_ContactoCDiscapPaterno: '', p_ContactoCDiscapMaterno: '', p_IDGrupoCDiscap: 0, p_ContactoEmergencia: '', p_TelefonoContactoEmer: '', p_IDUsuario: 0
   });


    this.modalAgregarCongresista = this.modalService.open(this.modalAddCongresista, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }
  openDesactivar(idContacto, nombreCat) {
    this._idContactoEliminar = idContacto;
    this._nombreUsuario = nombreCat;
    this.modalEliminarCongresista = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }
  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Congresista';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
  obtenerCatalogoGenerico(p_idCatGenerico) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoGenerico?p_idCatGenerico=" + p_idCatGenerico)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            switch (p_idCatGenerico) {
              case 1:
                this.listaTiempoMilitancia = tempdate.response as Lista_catalogo_generico_response[];
                break;
              case 2:
                this.listaGenero = tempdate.response as Lista_catalogo_generico_response[];
                break;
              case 3:
                this.listaOrientacionSexual = tempdate.response as Lista_catalogo_generico_response[];
                break;
              case 4:
                this.listaDiscapacidad = tempdate.response as Lista_catalogo_generico_response[];
                break;
              case 6:
                this.listaServicios = tempdate.response as Lista_catalogo_generico_response[];
                break;
            }
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerCatalogoGrupo() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoGrupo?p_estado=1&p_IDUsuario="+ this.idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaGrupos = tempdate.response as Lista_catalogo_grupo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerCatalogoGrupoPareja() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoGrupoPareja?p_estado=1&p_IDUsuario="+ this.idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaGruposPareja = tempdate.response as Lista_catalogo_grupo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  calculaEdad() {
    const fechaNacimiento = new Date(this.formGroupAgregarCongresista.value.p_FNac);
    const fechaActual = new Date();
    let edad = fechaActual.getFullYear() - fechaNacimiento.getFullYear();

    if (fechaActual < new Date(fechaActual.getFullYear(), fechaNacimiento.getMonth(), fechaNacimiento.getDate())) {
      edad--;
    }

    this.formGroupAgregarCongresista.get('p_Edad').setValue(edad);  
  }
  
  habilitaCampoDependencia(campo: string){

    switch (campo) {
      case 'p_LlevaServicio':
        if (this.formGroupAgregarCongresista.value.p_LlevaServicio == 1) {
          this.bnd_p_LlevaServicio = true;
        } else {
          this.bnd_p_LlevaServicio = false;
        }
        break;
      case 'p_ParejaEnElMovimiento':
        if (this.formGroupAgregarCongresista.value.p_ParejaEnElMovimiento == 1) {
          this.bnd_p_ParejaEnElMovimiento = true;
        } else {
          this.bnd_p_ParejaEnElMovimiento = false;
          this.formGroupAgregarCongresista.get('p_HospedarseConPareja').setValue(0);
        }
        break;
      case 'p_TieneDiscapacidad':
        if (this.formGroupAgregarCongresista.value.p_TieneDiscapacidad == 1) {
          this.bnd_p_TieneDiscapacidad = true;
        } else {
          this.bnd_p_TieneDiscapacidad = false;
        }
        break;
    }
  } 

  openPDF(idContacto, nombreCongresista) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Congresistas/GetCongresista_x_id_pdf?p_IDContacto=" + idContacto)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.CongresistaPdf = tempdate.response[0] as Usuario_congresista_pdf_modelo_response;

            const fechaNacimiento = new Date(this.CongresistaPdf.fechaNacimiento);
            const fechaActual = new Date();
            let edad = fechaActual.getFullYear() - fechaNacimiento.getFullYear();
            if (fechaActual < new Date(fechaActual.getFullYear(), fechaNacimiento.getMonth(), fechaNacimiento.getDate())) {
              edad--;
            }
        
            this.formGroupAgregarCongresista.get('p_Edad').setValue(edad);  


          } else {
            this.listaCongresistas = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.modalCongresistaPDF = this.modalService.open(this.ModalCongresistaPDF, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  generarPDF(_idContacto) {
    const options = {
      filename: 'Congresista_' + _idContacto + '.pdf',
      image: { type: 'jpeg', quality: 1.0 }, // Establece una calidad de imagen máxima (rango: 0.0 - 1.0)
      html2canvas: { scale: 2 }, // Configuración de rendering de HTML2Canvas
      jsPDF: { orientation: 'portrait' }
    };

    const element = document.getElementById('htmlPrint');

    html2pdf()
      .set(options)
      .from(element)
      .save();
  }

  cerrarModalPDF() {
    this.modalCongresistaPDF.close();
  }

}
