import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Grupo_modelo_response, Lista_catalogo_congreso_response, Lista_catalogo_contactos_response, Lista_catalogo_zona_response, Lista_grupos_response, Lista_planes_response } from 'src/model/Catalogos/Catalogos';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css'],
  providers: [ServiceGenerico]
})
export class GruposComponent implements OnInit {
  @ViewChild("modalAddGrupo", { static: false }) modalAddGrupo: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarGrupo: NgbModalRef;
  modalEliminarGrupo: NgbModalRef;

  formGroupAgregarGrupos: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  listaCongresos: Lista_catalogo_congreso_response[];
  formGroupCongreso: FormGroup;
  // Grupo: Grupo_modelo_response;
  _idGrupoEliminar: any;
  _nombreGrupo: any;
  _bndCongresoSelected: boolean = false;
  listaGrupos: Lista_grupos_response[];
  Grupo: Grupo_modelo_response;
  listaContactos: Lista_catalogo_contactos_response[];
  listaZonas: Lista_catalogo_zona_response[];
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormAgregarGrupos();
    this.obtenerGrupos();
    this.obtenerCatalogoContactos();
    this.titulo = "Grupo";
    this.obtenerCatalogoZona();
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerGrupos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoGrupo?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaGrupos = [] = tempdate.response as Lista_grupos_response[];
            this.renderTabla();
          } else {
            this.listaGrupos = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarGrupos() {
    this.formGroupAgregarGrupos = this.fb.group({
      p_IDGrupo: [0], 
      p_NombreGrupo: ["", [Validators.required]], 
      p_DescripcionGrupo: [""], 
      p_Tel1: ["", [Validators.required, Validators.pattern(/^[0-9.]*$/)]], 
      p_IDContactoResponsable: [0],
      p_IDZona: [0] 
    });

  }

  agregarGrupo() {
    this.funcionalidad = 'Nuevo';
    this.openModalFormGrupo(true);
  }

  GuardarGrupo() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarGrupos.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostGrupo")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerGrupos();
            this.modalAgregarGrupo.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutGrupo")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerGrupos();
              this.modalAgregarGrupo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  editarverDetalleGrupo(idGrupo, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormGrupo(false);
    } else {
      this.funcionalidad = 'Editar';
      this.openModalFormGrupo(true);
      this.esEditable = true;
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetGrupo_x_id?p_IDGrupo=" + idGrupo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Grupo = tempdate.response as Grupo_modelo_response;
            this.formGroupAgregarGrupos.get('p_IDGrupo').setValue(this.Grupo[0].idGrupo);
            this.formGroupAgregarGrupos.get('p_IDZona').setValue(this.Grupo[0].idZona);
            this.formGroupAgregarGrupos.get('p_NombreGrupo').setValue(this.Grupo[0].nombreGrupo);
            this.formGroupAgregarGrupos.get('p_DescripcionGrupo').setValue(this.Grupo[0].descripcionGrupo);
            this.formGroupAgregarGrupos.get('p_Tel1').setValue(this.Grupo[0].tel1);
            this.formGroupAgregarGrupos.get('p_IDContactoResponsable').setValue(this.Grupo[0].idContactoResponsable);
          } else {
            this.listaGrupos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarGrupo(_idGrupoEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteGrupo?p_IDGrupo=" + _idGrupoEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerGrupos();
            this.modalEliminarGrupo.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormGrupo(esEditable: boolean) {
    this.esEditable = esEditable;
   
    this.formGroupAgregarGrupos.reset({ 
      p_IDGrupo: 0, 
      p_NombreGrupo: "", 
      p_DescripcionGrupo: "", 
      p_Tel1: "", 
      p_IDContactoResponsable: 0, 
      p_IDZona: 0
    });
    this.modalAgregarGrupo = this.modalService.open(this.modalAddGrupo, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idGrupo, nombreGrupo) {
    this._idGrupoEliminar = idGrupo;
    this._nombreGrupo = nombreGrupo;
    this.modalEliminarGrupo = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Grupo';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  obtenerCatalogoContactos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoContactos?p_estado=1&p_Filtro=Grupo&p_IDContacto=0")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaContactos = tempdate.response as Lista_catalogo_contactos_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  
  obtenerCatalogoZona() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoZona?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaZonas = tempdate.response as Lista_catalogo_zona_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  }
