import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_contactos_response, Lista_zonas_response, Zona_modelo_response } from 'src/model/Catalogos/Catalogos';
import { ServiceGenerico } from 'src/services/service-generico.service';
@Component({
  selector: 'app-zonas',
  templateUrl: './zonas.component.html',
  styleUrls: ['./zonas.component.css'],
  providers: [ServiceGenerico]
})
export class ZonasComponent implements OnInit {
  @ViewChild("modalAddZona", { static: false }) modalAddZona: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarZona: NgbModalRef;
  modalEliminarZona: NgbModalRef;

  formGroupAgregarZonas: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  _idZonaEliminar: any;
  _nombreZona: any;
  _bndCongresoSelected: boolean = false;
  listaZona: Lista_zonas_response[];
  Zona: Zona_modelo_response;
  listaContactos: Lista_catalogo_contactos_response[];
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormAgregarZonas();
    this.obtenerZonas();
    this.titulo = "Zona";
    this.obtenerCatalogoContactos();
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerZonas() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoZona?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaZona = [] = tempdate.response as Lista_zonas_response[];
            this.renderTabla();
          } else {
            this.listaZona = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarZonas() {
    this.formGroupAgregarZonas = this.fb.group({
      p_IDZona: [0], 
      p_Nombre: ["", [Validators.required]], 
      p_Descripcion: [""], 
      p_IDContactoResponsable: [0], 
    });

  }

  agregarZona() {
    this.funcionalidad = 'Nuevo';
    this.openModalFormZona(true);
  }

  GuardarZona() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarZonas.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostZona")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerZonas();
            this.modalAgregarZona.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutZona")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerZonas();
              this.modalAgregarZona.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  editarverDetalleZona(idZona, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormZona(false);
    } else {
      this.funcionalidad = 'Editar';
      this.openModalFormZona(true);
      this.esEditable = true;
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetZona_x_id?p_IDZona=" + idZona)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Zona = tempdate.response as Zona_modelo_response;
            this.formGroupAgregarZonas.get('p_IDZona').setValue(this.Zona[0].idZona);
            this.formGroupAgregarZonas.get('p_Nombre').setValue(this.Zona[0].nombre);
            this.formGroupAgregarZonas.get('p_Descripcion').setValue(this.Zona[0].descripcion);
            this.formGroupAgregarZonas.get('p_IDContactoResponsable').setValue(this.Zona[0].idContactoResponsable);
          } else {
            this.listaZona = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarZona(_idZonaEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteZona?p_IDZona=" + _idZonaEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerZonas();
            this.modalEliminarZona.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormZona(esEditable: boolean) {
    this.esEditable = esEditable;
   
    this.formGroupAgregarZonas.reset({ 
      p_IDZona: 0, 
      p_NombreZona: "", 
      p_DescripcionZona: "", 
      p_Tel1: "", 
      p_IDContactoResponsable: 0, 
    });
    this.modalAgregarZona = this.modalService.open(this.modalAddZona, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idZona, nombreZona) {
    this._idZonaEliminar = idZona;
    this._nombreZona = nombreZona;
    this.modalEliminarZona = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Zona';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
  obtenerCatalogoContactos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoContactos?p_estado=1&p_Filtro=Zona&p_IDContacto=0")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaContactos = tempdate.response as Lista_catalogo_contactos_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  }
