import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_contactos_response, Lista_hoteles_response, Hotel_modelo_response } from 'src/model/Catalogos/Catalogos';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-hoteles',
  templateUrl: './hoteles.component.html',
  styleUrls: ['./hoteles.component.css'],
  providers: [ServiceGenerico]
})
export class HotelesComponent implements OnInit {
  @ViewChild("modalAddHotel", { static: false }) modalAddHotel: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarHotel: NgbModalRef;
  modalEliminarHotel: NgbModalRef;

  formGroupAgregarHoteles: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  _idHotelEliminar: any;
  _nombreHotel: any;
  _bndCongresoSelected: boolean = false;
  listaHotel: Lista_hoteles_response[];
  Hotel: Hotel_modelo_response;
  listaContactos: Lista_catalogo_contactos_response[];
  formGroupAsignarHabitacion: FormGroup;
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormAgregarHoteles();
    this.obtenerHoteles();
    this.titulo = "Hotel";
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerHoteles() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoHotel?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaHotel = [] = tempdate.response as Lista_hoteles_response[];
            this.renderTabla();
          } else {
            this.listaHotel = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarHoteles() {
    this.formGroupAgregarHoteles = this.fb.group({
      p_IDHotel: [0], 
      p_IDCongreso: [0], 
      p_Nombre: ["", [Validators.required]], 
      p_Direccion: [""], 
      p_TotalTorres: [0], 
      p_TotalPisos: [0], 
      p_HabitacionSencilla: [0], 
      p_HabitacionDoble: [0], 
      p_HabitacionTriple: [0], 
      p_TotalHabitaciones: [0], 
      p_TotalHabitacionesOcupadas: [0], 
      p_TotalHabitacionesDisponibles: [0], 
    });
  }

  inicializarFormAsignarHabitacion() {
    this.formGroupAsignarHabitacion = this.fb.group({
      p_TipoHabitacion: [0], 
      p_ClaveAsignacio: [0], 
      p_IDContactoCongresista_1: [0], 
      p_NombreCongresista_1: [0], 
      p_PorcentajePago_1: [0], 
      p_IDContactoCongresista_2: [0], 
      p_NombreCongresista_2: [0], 
      p_PorcentajePago_2: [0], 
      p_IDContactoCongresista_3: [0], 
      p_NombreCongresista_3: [0], 
      p_PorcentajePago_3: [0], 
      p_IDContactoCongresista_4: [0], 
      p_NombreCongresista_4: [0], 
      p_PorcentajePago_4: [0], 
      p_ComentariosAsignacion: [""] 
    });
  }

  agregarHotel() {
    this.funcionalidad = 'Nuevo';
    this.openModalFormHotel(true);
  }

  GuardarHotel() {
    this.operacionRespuesta.EstaEjecutando = true;

    let model = this.formGroupAgregarHoteles.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostHotel")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerHoteles();
            this.modalAgregarHotel.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutHotel")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerHoteles();
              this.modalAgregarHotel.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  editarverDetalleHotel(idHotel, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormHotel(false);
    } else {
      this.funcionalidad = 'Editar';
      this.openModalFormHotel(true);
      this.esEditable = true;
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetHotel_x_id?p_IDHotel=" + idHotel)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Hotel = tempdate.response as Hotel_modelo_response;
            this.formGroupAgregarHoteles.get('p_IDHotel').setValue(this.Hotel[0].idHotel);
            this.formGroupAgregarHoteles.get('p_IDCongreso').setValue(this.Hotel[0].idCongreso);
            this.formGroupAgregarHoteles.get('p_Nombre').setValue(this.Hotel[0].nombre);
            this.formGroupAgregarHoteles.get('p_Direccion').setValue(this.Hotel[0].direccion);
            this.formGroupAgregarHoteles.get('p_TotalTorres').setValue(this.Hotel[0].totalTorres);
            this.formGroupAgregarHoteles.get('p_TotalPisos').setValue(this.Hotel[0].totalPisos);
            this.formGroupAgregarHoteles.get('p_HabitacionSencilla').setValue(this.Hotel[0].habitacionSencilla);
            this.formGroupAgregarHoteles.get('p_HabitacionDoble').setValue(this.Hotel[0].habitacionDoble);
            this.formGroupAgregarHoteles.get('p_HabitacionTriple').setValue(this.Hotel[0].habitacionTriple);
            this.formGroupAgregarHoteles.get('p_TotalHabitaciones').setValue(this.Hotel[0].totalHabitaciones);
            this.formGroupAgregarHoteles.get('p_TotalHabitacionesOcupadas').setValue(this.Hotel[0].habitacionesOcupadas);
            this.formGroupAgregarHoteles.get('p_TotalHabitacionesDisponibles').setValue(this.Hotel[0].habitacionesDisponibles);
          } else {
            this.listaHotel = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarHotel(_idHotelEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteHotel?p_IDHotel=" + _idHotelEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerHoteles();
            this.modalEliminarHotel.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormHotel(esEditable: boolean) {
    this.esEditable = esEditable;
   
    this.formGroupAgregarHoteles.reset({ 
      p_IDHotel: 0, 
      p_NombreHotel: "", 
      p_DescripcionHotel: "", 
      p_Tel1: "", 
      p_IDContactoResponsable: 0, 
    });
    this.modalAgregarHotel = this.modalService.open(this.modalAddHotel, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idHotel, nombreHotel) {
    this._idHotelEliminar = idHotel;
    this._nombreHotel = nombreHotel;
    this.modalEliminarHotel = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Hotel';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
  }
