import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_congreso_response, Lista_planes_response, Plan_modelo_response } from 'src/model/Catalogos/Catalogos';
import { ServiceGenerico } from 'src/services/service-generico.service';
@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css'],
  providers: [ServiceGenerico]
})
export class ConfiguracionComponent implements OnInit {
  modalrefMsg: NgbModalRef;
  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  listaCongresos: Lista_catalogo_congreso_response[];
  formGroupCongreso: FormGroup;

  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializarFormAgregarCongreso();
    this.obtenerCatalogoCongreso();
    this.getCongresoConfiguracion();
  }

  inicializarFormAgregarCongreso() {
    this.formGroupCongreso = this.fb.group({
      p_IDCongreso: [0, [this.validateSelectedValue]]
    });
  }

  validateSelectedValue(control: AbstractControl) {
    if (control.value === 0) {
      return { invalidValue: true };
    }
    return { invalidValue: false };
  }

  obtenerCatalogoCongreso() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoCongreso?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCongresos = tempdate.response as Lista_catalogo_congreso_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  getCongresoConfiguracion() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetConfiguracion_x_id?p_IDConfiguracion=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.formGroupCongreso.get('p_IDCongreso').setValue(tempdate.response[0].idCongreso);
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }


  GuardarCongreso() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupCongreso.value;

    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostConfiguracion")
    .subscribe(
      (tempdate) => {
        this.openMensajes(tempdate.response[0].msg, false, '');
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async () => {
        this.openMensajes("No se pudo realizar la acción", true, '');
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );

  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Configuración del sistema';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
}

