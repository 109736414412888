import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_congreso_response, Lista_planes_response, Plan_modelo_response } from 'src/model/Catalogos/Catalogos';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.css'],
  providers: [ServiceGenerico]

})
export class PlanesComponent implements OnInit {
  @ViewChild("modalAddPlan", { static: false }) modalAddPlan: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarPlan: NgbModalRef;
  modalEliminarPlan: NgbModalRef;

  formGroupAgregarPlanes: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  listaCongresos: Lista_catalogo_congreso_response[];
  listaPlanes: Lista_planes_response[];
  formGroupCongreso: FormGroup;
  Plan: Plan_modelo_response;
  _idPlanEliminar: any;
  _nombrePlan: any;
  _bndCongresoSelected: boolean = false;
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormAgregarPlanes();
    this.inicializarFormAgregarCongreso();
      this.titulo = "Planes";
    this.obtenerCatalogoCongreso(); 
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerPlanes() {
    this.operacionRespuesta.EstaEjecutando = true;
    let _idCongreso = this.formGroupCongreso.value.p_IDCongreso;

    if (_idCongreso != 0) {
      this._bndCongresoSelected = true;
    } 

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoPlanes?p_IDCongreso="+ _idCongreso)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaPlanes = [] = tempdate.response as Lista_planes_response[];
            this.renderTabla();
          } else {
            this.listaPlanes = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarPlanes() {
    this.formGroupAgregarPlanes = this.fb.group({
      p_IDCongreso: [0], 
      p_IDPlan: [0], 
      p_NombrePlan: ["", [Validators.required]], 
      p_DescripcionPlan: [""], 
      p_FechaCostoInicio: ["", [Validators.required]], 
      p_FechaCostoFin: ["", [Validators.required]], 
      p_CostoPesos: ["",[Validators.pattern(/^[0-9.]*$/)]], 
      p_CostoDolares: ["",[Validators.pattern(/^[0-9.]*$/)]], 
      p_CostoEuros: ["",[Validators.pattern(/^[0-9.]*$/)]]
    });

  }
  inicializarFormAgregarCongreso() {
    this.formGroupCongreso = this.fb.group({
      p_IDCongreso: [0] 
    });

  }

  agregarPlan() {
    this.funcionalidad = 'Nuevo';
    this.openModalFormPlan(true);
  }

  GuardarPlan() {
    this.formGroupAgregarPlanes.get('p_IDCongreso').setValue(this.formGroupCongreso.value.p_IDCongreso);

    this.formGroupCongreso
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarPlanes.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostPlan")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerPlanes();
            this.modalAgregarPlan.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutPlan")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerPlanes();
              this.modalAgregarPlan.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  editarverDetallePlan(idPlan, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormPlan(false);
    } else {
      this.funcionalidad = 'Editar';
      this.openModalFormPlan(true);
      this.esEditable = true;
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetPlan_x_id?p_IDPlan=" + idPlan)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Plan = tempdate.response as Plan_modelo_response;
            this.formGroupAgregarPlanes.get('p_IDPlan').setValue(this.Plan[0].idPlan);
            this.formGroupAgregarPlanes.get('p_IDCongreso').setValue(this.Plan[0].idCongreso);
            this.formGroupAgregarPlanes.get('p_NombrePlan').setValue(this.Plan[0].nombrePlan);
            this.formGroupAgregarPlanes.get('p_DescripcionPlan').setValue(this.Plan[0].descripcionPlan);
            this.formGroupAgregarPlanes.get('p_CostoPesos').setValue(this.Plan[0].costoPesos);
            this.formGroupAgregarPlanes.get('p_CostoDolares').setValue(this.Plan[0].costoDolares);
            this.formGroupAgregarPlanes.get('p_CostoEuros').setValue(this.Plan[0].costoEuros);
       
            const fechaCostoInicio = new Date(this.Plan[0].fechaCostoInicio);
            const fechaCortaI = fechaCostoInicio.toISOString().split('T')[0];
            this.formGroupAgregarPlanes.get('p_FechaCostoInicio').setValue(fechaCortaI);
            const fechaCostoFin = new Date(this.Plan[0].fechaCostoFin);
            const fechaCortaF = fechaCostoFin.toISOString().split('T')[0];
            this.formGroupAgregarPlanes.get('p_FechaCostoFin').setValue(fechaCortaF);

          } else {
            this.listaPlanes = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarPlan(_idPlanEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeletePlan?p_IDPlan=" + _idPlanEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerPlanes();
            this.modalEliminarPlan.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormPlan(esEditable: boolean) {
    this.esEditable = esEditable;
   
    this.formGroupAgregarPlanes.reset({ 
      p_IDCongreso: 0, 
      p_IDPlan: 0, 
      p_NombrePlan: "", 
      p_DescripcionPlan: "", 
      p_FechaCostoInicio: "", 
      p_FechaCostoFin: "", 
      p_CostoPesos: "", 
      p_CostoDolares: "", 
      p_CostoEuros: ""
    });
    this.modalAgregarPlan = this.modalService.open(this.modalAddPlan, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idPlan, nombrePlan) {
    this._idPlanEliminar = idPlan;
    this._nombrePlan = nombrePlan;
    this.modalEliminarPlan = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Plan';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  obtenerCatalogoCongreso() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoCongreso?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCongresos = tempdate.response as Lista_catalogo_congreso_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
 
  }
