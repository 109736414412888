import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_contactos_response, Lista_catalogo_generico_response } from 'src/model/Catalogos/Catalogos';
import { Lista_ordenes_response, Orden_modelo_response } from 'src/model/Catalogos/Ordenes';
import { ServiceGenerico } from 'src/services/service-generico.service';
@Component({
  selector: 'app-ordenes',
  templateUrl: './ordenes.component.html',
  styleUrls: ['./ordenes.component.css'],
  providers: [ServiceGenerico]
})
export class OrdenesComponent implements OnInit {
  @Input() esValidacionOrden: boolean;
  @ViewChild("modalAddOrden", { static: false }) modalAddOrden: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarOrden: NgbModalRef;
  modalEliminarOrden: NgbModalRef;

  formGroupAgregarOrdenes: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  _idOrdenEliminar: any;
  _nombreOrden: any;
  _bndCongresoSelected: boolean = false;
  listaOrden: Lista_ordenes_response[];
  Orden: Orden_modelo_response;
  listaContactos: Lista_catalogo_contactos_response[];
  listaServicio: Lista_catalogo_generico_response[];
  listaComite: Lista_catalogo_generico_response[];
  formData: FormData;
  archivos: File[] = []; 
  urls: string[];
  urlsPago: string[];
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private http: HttpClient  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormAgregarOrdenes();
    this.obtenerOrdenes();
    // this.titulo = "Orden";
    this.obtenerCatalogoContactos();
    this.obtenerCatalogoGenerico(9); //Comité
    this.obtenerCatalogoGenerico(6); //Servicio

    if (this.esValidacionOrden) {
      this.titulo = "Validar órden de compra";
    }else {
      this.titulo = "Órden de Compra";
    }
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerOrdenes() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Ordenes/GetListaCatalogoOrden?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaOrden = [] = tempdate.response as Lista_ordenes_response[];
            this.renderTabla();
          } else {
            this.listaOrden = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerCatalogoGenerico(p_idCatGenerico) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoGenerico?p_idCatGenerico=" + p_idCatGenerico)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            switch (p_idCatGenerico) {
              case 6:
                this.listaServicio = tempdate.response as Lista_catalogo_generico_response[];
                break;
              case 9:
                this.listaComite = tempdate.response as Lista_catalogo_generico_response[];
                break;
            }
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }


  inicializarFormAgregarOrdenes() {
    this.formGroupAgregarOrdenes = this.fb.group({
      p_IDOrden: [0], 
      p_FechaRegistro: [""],
      p_IDComite: [0],
      p_IDServicio: [0],
      p_NombreSolicitante: [""],
      p_FechaPagoRequerido: [""],
      p_Observaciones: [""],
      p_NombreAutoriza: [""],
      p_Comentario: [""],
      });

  }

  agregarOrden() {
    this.funcionalidad = 'Nuevo';
    this.formData = new FormData();
    this.archivos = [];
    this.openModalFormOrden(true);
  }

  GuardarOrden() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarOrdenes.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Ordenes/PostOrden")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.subirArchivos(tempdate.response[0].success.toString());
            this.obtenerOrdenes();
            this.modalAgregarOrden.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Ordenes/PutOrden")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.subirArchivos(this.formGroupAgregarOrdenes.value.p_IDOrden.toString());
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerOrdenes();
              this.modalAgregarOrden.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  ActualizarOrden(_opcion: string) {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarOrdenes.value;
    if (_opcion === 'Confirmar') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Ordenes/ConfirmarPagoOrden")
      .subscribe(
        (tempdate) => {
          this.subirArchivosPago(this.formGroupAgregarOrdenes.value.p_IDOrden.toString());
          this.openMensajes(tempdate.response[0].msg, false, '');
          this.obtenerOrdenes();
          this.modalAgregarOrden.close();

          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    } else 
    {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Ordenes/DeclinarPagoOrden")
      .subscribe(
        (tempdate) => {
          this.subirArchivosPago(this.formGroupAgregarOrdenes.value.p_IDOrden.toString());
          this.openMensajes(tempdate.response[0].msg, false, '');
          this.obtenerOrdenes();
          this.modalAgregarOrden.close();

          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
    }

  }

  editarverDetalleOrden(idOrden, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormOrden(false);
    } else if (opcion === 'Editar') {
      this.funcionalidad = 'Editar';
      this.openModalFormOrden(true);
      this.esEditable = true;
    } else if (opcion === 'Validar'){ 
      this.funcionalidad = 'Validar';
      this.openModalFormOrden(false);
    }
    this.ObtenerComprobantesOrden(idOrden);
    this.ObtenerComprobantesOrdenPago(idOrden);
    this.formData = new FormData();
    this.archivos = [];
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Ordenes/GetOrden_x_id?p_IDOrden=" + idOrden)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Orden = tempdate.response as Orden_modelo_response;
            this.formGroupAgregarOrdenes.get('p_IDOrden').setValue(this.Orden[0].idOrden);

            const fechaRegistro = new Date(this.Orden[0].fechaPagoRequerido);
            const fechaCortaR = fechaRegistro.toISOString().split('T')[0];
            this.formGroupAgregarOrdenes.get('p_FechaRegistro').setValue(fechaCortaR);

            this.formGroupAgregarOrdenes.get('p_IDComite').setValue(this.Orden[0].idComite);
            this.formGroupAgregarOrdenes.get('p_IDServicio').setValue(this.Orden[0].idServicio);
            this.formGroupAgregarOrdenes.get('p_NombreSolicitante').setValue(this.Orden[0].nombreSolicitante);
            
            const fechaPagoRequerido = new Date(this.Orden[0].fechaPagoRequerido);
            const fechaCortaPR = fechaPagoRequerido.toISOString().split('T')[0];
            this.formGroupAgregarOrdenes.get('p_FechaPagoRequerido').setValue(fechaCortaPR);
            
            this.formGroupAgregarOrdenes.get('p_Observaciones').setValue(this.Orden[0].observaciones);
            this.formGroupAgregarOrdenes.get('p_NombreAutoriza').setValue(this.Orden[0].nombreAutoriza);
            this.formGroupAgregarOrdenes.get('p_Comentario').setValue(this.Orden[0].comentario);
          } else {
            // this.listaOrden = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarOrden(_idOrdenEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Ordenes/DeleteOrden?p_IDOrden=" + _idOrdenEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerOrdenes();
            this.modalEliminarOrden.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormOrden(esEditable: boolean) {
    this.esEditable = esEditable;
   
    this.formGroupAgregarOrdenes.reset({ 
      p_IDOrden: 0, 
      p_FechaRegistro: "", 
      p_IDComite: 0, 
      p_IDServicio: 0, 
      p_NombreSolicitante: "", 
      p_FechaPagoRequerido: "", 
      p_Observaciones: "", 
      p_NombreAutoriza: "", 
      p_Comentario: "", 
    });

    this.modalAgregarOrden = this.modalService.open(this.modalAddOrden, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idOrden, nombreOrden) {
    this._idOrdenEliminar = idOrden;
    this._nombreOrden = nombreOrden;
    this.modalEliminarOrden = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Orden';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }
  obtenerCatalogoContactos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Ordenes/GetCatalogoContactos?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaContactos = tempdate.response as Lista_catalogo_contactos_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  agregarComprobante(event: any) {
    const files = event.target.files;
    this.formData = new FormData();

    if (files) {
      // Contadores para rastrear la cantidad de archivos y las posiciones en formData
      let archivosAgregados = 0;
      let formDataPosicion = 0;
      let archivosEliminados = 0; // Contador de archivos eliminados
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSizeInMB = file.size / (1024 * 1024); // Convertir el tamaño a MB
  
        if (fileSizeInMB > 5) {
          this.openMensajes("Un archivo es demasiado grande (máximo 5 MB): " + file.name, true, '');
          event.target.value = null; // Borra el input
          archivosEliminados++;
        } else if (archivosAgregados < 5 && formDataPosicion < 5) {
          // Agregar el archivo a formData solo si no se ha superado el límite de archivos y posiciones
          this.formData.append('archivos', file);
          this.archivos.push(file); // Agrega el archivo a la lista de archivos
          archivosAgregados++;
          formDataPosicion++;
        } else {
          this.openMensajes("Se supera el límite de archivos a cargar", true, '');
          event.target.value = null; // Borra el input si se supera el límite de archivos
          archivosEliminados++;
        }
      }
  
      if (archivosEliminados === files.length) {
        // Todos los archivos se eliminaron, puedes mostrar un mensaje de error si lo deseas.
        this.openMensajes("Todos los archivos son demasiado grandes o excede el límite de archivos", true, '');
      }
    }
  
  }
  

  subirArchivos(_IDRemesa) {
    const formData = new FormData();

    for (const archivo of this.archivos) {
      formData.append("p_FormData", archivo);
    }

    formData.append("p_IDOrden", _IDRemesa.toString());

    this.http
      .post(this.modelo_configuracion.serviciosOperaciones + "/Ordenes/subir-comprobante", formData)
      .subscribe(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  ObtenerComprobantesOrden(_idOrden) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Ordenes/ObtenerComprobantes?idOrden=" + _idOrden)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.urls = tempdate as string[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }


// archivos comprobate pago 

subirArchivosPago(_IDRemesa) {
  const formData = new FormData();

  for (const archivo of this.archivos) {
    formData.append("p_FormData", archivo);
  }

  formData.append("p_IDOrden", _IDRemesa.toString());

  this.http
    .post(this.modelo_configuracion.serviciosOperaciones + "/Ordenes/subir-comprobante-pago", formData)
    .subscribe(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.error(error);
      }
    );
}

ObtenerComprobantesOrdenPago(_idOrden) {
  this.operacionRespuesta.EstaEjecutando = true;
  this.services
    .HttpGet(
      this.modelo_configuracion.serviciosOperaciones + "/Ordenes/ObtenerComprobantesPago?idOrden=" + _idOrden)
    .subscribe(
      (tempdate) => {
        if (tempdate) {
          this.urlsPago = tempdate as string[];
        }
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async () => {
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}



  }
