import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_contactos_response, Lista_perfiles_response, Lista_permisos_response, Perfil_modelo_response } from 'src/model/Catalogos/Catalogos';
import { ServiceGenerico } from 'src/services/service-generico.service';

@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['./perfiles.component.css'],
  providers: [ServiceGenerico]
})
export class PerfilesComponent implements OnInit {
  @ViewChild("modalAddPerfil", { static: false }) modalAddPerfil: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  @ViewChild("modalConfigurarPerfil", { static: false }) ModalConfigurarPerfil: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarPerfil: NgbModalRef;
  modalEliminarPerfil: NgbModalRef;
  modalConfigPerfil: NgbModalRef;

  formGroupAgregarPerfiles: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  _idPerfil: number;
  _idPerfilEliminar: any;
  _nombrePerfil: any;
  _bndCongresoSelected: boolean = false;
  listaPerfil: Lista_perfiles_response[];
  Perfil: Perfil_modelo_response;
  listaContactos: Lista_catalogo_contactos_response[];
  selectedCheckboxes:any[] = [] ;
  ListaPermisos: Lista_permisos_response[];
  listaModulos: Lista_permisos_response[];
  modulosUnicos: any[];
  subModulosUnicos: any[];
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormAgregarPerfiles();
    this.obtenerPerfiles();
    this.obtenerModulos();
    this.titulo = "Perfil";
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerPerfiles() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoPerfil?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaPerfil = [] = tempdate.response as Lista_perfiles_response[];
            this.renderTabla();
          } else {
            this.listaPerfil = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarPerfiles() {
    this.formGroupAgregarPerfiles = this.fb.group({
      p_IDPerfil: [0], 
      p_Nombre: ["", [Validators.required]], 
      p_Descripcion: [""], 
    });

  }

  agregarPerfil() {
    this.funcionalidad = 'Nuevo';
    this.openModalFormPerfil(true);
  }

  GuardarPerfil() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarPerfiles.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostPerfil")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerPerfiles();
            this.modalAgregarPerfil.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutPerfil")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerPerfiles();
              this.modalAgregarPerfil.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  editarverDetallePerfil(idPerfil, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormPerfil(false);
    } else {
      this.funcionalidad = 'Editar';
      this.openModalFormPerfil(true);
      this.esEditable = true;
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetPerfil_x_id?p_IDPerfil=" + idPerfil)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Perfil = tempdate.response as Perfil_modelo_response;
            this.formGroupAgregarPerfiles.get('p_IDPerfil').setValue(this.Perfil[0].idPerfil);
            this.formGroupAgregarPerfiles.get('p_Nombre').setValue(this.Perfil[0].nombre);
            this.formGroupAgregarPerfiles.get('p_Descripcion').setValue(this.Perfil[0].descripcion);
          } else {
            this.listaPerfil = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarPerfil(_idPerfilEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeletePerfil?p_IDPerfil=" + _idPerfilEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerPerfiles();
            this.modalEliminarPerfil.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  openModalFormPerfil(esEditable: boolean) {
    this.esEditable = esEditable;
   
    this.formGroupAgregarPerfiles.reset({ 
      p_IDPerfil: 0, 
      p_NombrePerfil: "", 
      p_DescripcionPerfil: "", 
      p_Tel1: "", 
      p_IDContactoResponsable: 0, 
    });
    this.modalAgregarPerfil = this.modalService.open(this.modalAddPerfil, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idPerfil, nombrePerfil) {
    this._idPerfilEliminar = idPerfil;
    this._nombrePerfil = nombrePerfil;
    this.modalEliminarPerfil = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Perfil';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  obtenerModulos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoPermiso?p_estado=1"
      )
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaModulos = tempdate.response as Lista_permisos_response[];
  
            this.modulosUnicos = [];
            this.subModulosUnicos = [];
  
            this.listaModulos.forEach(item => {
              if (!this.modulosUnicos.some(modulo => modulo.idModulo === item.idModulo)) {
                this.modulosUnicos.push({
                  idModulo: item.idModulo,
                  nombreModulo: item.nombreModulo,
                });
              }
  
              this.subModulosUnicos.push({
                idSubModulo: item.idSubModulo,
                nombreSubModulo: item.nombreSubModulo,
                idModulo: item.idModulo, // Asegúrate de incluir el idModulo al submódulo
              });
            });
          } else {
            this.listaPerfil = [];
          }
  
          this.operacionRespuesta.EstaEjecutando = false;
        },
        () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  getSubmodulos(moduloId: number) {
    return this.subModulosUnicos.filter(submodulo => submodulo.idModulo === moduloId);
  }
  

  configurarPermisos(idPerfil) {
    this.selectedCheckboxes = [];
    this._idPerfil = idPerfil;
    this.esEditable = true;
    
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetPermisos_x_id?p_IDPerfil=" + idPerfil)
    .subscribe(
      (tempdate) => {
        if (tempdate) {

          // Obtener todos los elementos de tipo checkbox en la página
          const checkboxes = document.querySelectorAll('input[type="checkbox"]');

          // Recorrer los checkboxes y comparar sus ids con los de this.listaNegocios
          checkboxes.forEach((checkbox: HTMLInputElement) => {
            if (checkbox.id.startsWith('checkbox_SubModulo_')) {
              const checkboxId = parseInt(checkbox.id.split('_')[2]); // Convertir el id del checkbox a número

              // Verificar si el id del checkbox está presente en this.listaNegocios
              const negocioEncontrado = tempdate.response.find(permiso => permiso.idSubModulo === checkboxId);

              if (negocioEncontrado) {
                const p_IDPerfil = this._idPerfil;
                const p_IDSubModulo = checkboxId;
                const nuevoPermiso = {
                  p_IDPerfil,
                  p_IDSubModulo
                }
                this.selectedCheckboxes.push(nuevoPermiso);

                checkbox.checked = true; // Marcar el checkbox como "checked"
                
              }
            }
          });
        } 
        this.operacionRespuesta.EstaEjecutando = false;
      },
      async (err) => {
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );


    this.modalConfigPerfil = this.modalService.open(this.ModalConfigurarPerfil, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });

  }
  onCheckboxChange(event: any) {
    let checkboxValue = event.target.value;
    const p_IDPerfil = this._idPerfil;
    const p_IDSubModulo = parseInt(checkboxValue.split('_')[2]);
    const nuevoPermiso = {
      p_IDPerfil,
      p_IDSubModulo
    }

    if (event.target.checked) {
      // Verificar si el objeto ya está en el arreglo
      const exists = this.selectedCheckboxes.some(item => 
        item.p_IDPerfil === nuevoPermiso.p_IDPerfil &&
        item.p_IDSubModulo === nuevoPermiso.p_IDSubModulo
      );
    
      if (!exists) {
        this.selectedCheckboxes.push(nuevoPermiso);
      }
    } else {
      // Eliminar el objeto del arreglo si existe
      const index = this.selectedCheckboxes.findIndex(item => 
        item.p_IDPerfil === nuevoPermiso.p_IDPerfil &&
        item.p_IDSubModulo === nuevoPermiso.p_IDSubModulo
      );
    
      if (index > -1) {
        this.selectedCheckboxes.splice(index, 1);
      }
    }

  }

  GuardarConfiguracionPerfil() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.selectedCheckboxes;
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostConfiguracionPermisos")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerPerfiles();
            this.modalConfigPerfil.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );

  }
}
