import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject, concat } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_contactos_response, Lista_catalogo_generico_response, Lista_hoteles_response } from 'src/model/Catalogos/Catalogos';
import { Asignacion_modelo_response, Lista_asignaciones_modelo_response, Lista_pagos_modelo_response, Pago_modelo_response } from 'src/model/Catalogos/Renesas';
import { ServiceGenerico } from 'src/services/service-generico.service';
@Component({
  selector: 'app-asignar',
  templateUrl: './asignar.component.html',
  styleUrls: ['./asignar.component.css'],
  providers: [ServiceGenerico]
})
export class AsignarComponent implements OnInit {
  // @ViewChild("modalAddAsignacion", { static: false }) modalAddAsignacion: TemplateRef<any>;
  @ViewChild("modalSelccionarHabitacion", { static: false }) modalSelccionarHabitacion: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  // modalAgregarAsignacion: NgbModalRef;
  modalSelHabitacion: NgbModalRef;
  modalEliminarAsignacion: NgbModalRef;

  // formGroupAgregarAsignaciones: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  _nombreAsignacion: any;
  // _bndCongresoSelected: boolean = false;
  listaAsignacion: Lista_asignaciones_modelo_response[];
  Asignacion: Asignacion_modelo_response;
  // listaContactos: Lista_catalogo_contactos_response[];
  formGroupAsignarHabitacion: FormGroup;
  listaTipoHabitacion: Lista_catalogo_generico_response[];
  sencilla: boolean = false;
  doble: boolean = false;
  triple: boolean = false;
  cuadruple: boolean = false;
  listaContactos: Lista_catalogo_contactos_response[];
  pagoCongresista: Pago_modelo_response;
  listaPagos: Lista_pagos_modelo_response[];
  _idAsignacionEliminar: any;
  listaHotel: Lista_hoteles_response[];
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.inicializarFormAsignarHabitacion();
    this.obtenerAsignaciones();
    this.obtenerHoteles();
    this.obtenerCatalogoGenerico(8); //Tipo Habitacion
    this.obtenerCatalogoContactos();
    this.titulo = "Asignar Habitaciones";
    this.obtenerPagos();

  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  obtenerAsignaciones() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetListaAsignaciones?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaAsignacion = [] = tempdate.response as Lista_asignaciones_modelo_response[];
            this.renderTabla();
          } else {
            this.listaAsignacion = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerHoteles() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogoHotel?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaHotel = [] = tempdate.response as Lista_hoteles_response[];
            this.renderTabla();
          } else {
            this.listaHotel = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  tipoHabitacionValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value === 0) {
        return { 'tipoHabitacionInvalid': true };
      }
      return null;
    };
  }

  inicializarFormAsignarHabitacion() {
    this.formGroupAsignarHabitacion = this.fb.group({
      p_ClaveAsignacion: [""],
      p_IDHotel: [0],
      p_TipoHabitacion: [0],
      p_Torre: [""],
      p_Piso: [""],
      p_IDContactoCongresista_1: [0],
      p_NombreCongresista_1: [""],
      p_PorcentajePago_1: [0],
      p_IDContactoCongresista_2: [0],
      p_NombreCongresista_2: [""],
      p_PorcentajePago_2: [0],
      p_IDContactoCongresista_3: [0],
      p_NombreCongresista_3: [""],
      p_PorcentajePago_3: [0],
      p_IDContactoCongresista_4: [0],
      p_NombreCongresista_4: [""],
      p_PorcentajePago_4: [0],
      p_ComentariosAsignacion: [""],
    
    });
  }

  agregarAsignacion() {
    this.funcionalidad = 'Nuevo';
    this.openModalAsignacion(true);
  }

  GuardarAsignacion() {
    this.operacionRespuesta.EstaEjecutando = true;

    let model = this.formGroupAsignarHabitacion.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Remesas/PostAsignacion")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerAsignaciones();
            this.modalSelHabitacion.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Remesas/PutAsignacion")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerAsignaciones();
              this.modalSelHabitacion.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  editarverDetalleAsignacion(idContacto, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalAsignacion(false);
    } else {
      this.funcionalidad = 'Editar';
      this.openModalAsignacion(true);
      this.esEditable = true;
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetAsignacion_x_id?p_IDContacto=" + idContacto)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Asignacion = tempdate.response as Asignacion_modelo_response;
            this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(this.Asignacion[0].idHabitacionAsignada);
            this.formGroupAsignarHabitacion.get('p_IDHotel').setValue(this.Asignacion[0].idHotel);
            this.formGroupAsignarHabitacion.get('p_TipoHabitacion').setValue(this.Asignacion[0].tipoHabitacion);
            this.formGroupAsignarHabitacion.get('p_Torre').setValue(this.Asignacion[0].torre);
            this.formGroupAsignarHabitacion.get('p_Piso').setValue(this.Asignacion[0].piso);
            this.obtenerLugaresHabitacion(this.Asignacion[0].tipoHabitacion.toString());
              for (var i = 0; i < this.Asignacion.length; i++) {
            this.formGroupAsignarHabitacion.get('p_IDContactoCongresista_'+ [i + 1]).setValue(this.Asignacion[0].idContacto);

            this.getPagoCongresista('p_IDContactoCongresista_'+ [i + 1], this.Asignacion[i].idContacto)
            // this.formGroupAsignarHabitacion.get('p_NombreCongresista_1').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].nombreCompleto);
            // this.formGroupAsignarHabitacion.get('p_PorcentajePago_1').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].importeRestante);
            // this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + _idCongresista);


            }
            this.formGroupAsignarHabitacion.get('p_ComentariosAsignacion').setValue(this.Asignacion[0].idAsignacion);
          } else {
            // this.listaAsignacion = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarAsignacion(_idAsignacionEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Remesas/DeleteAsignacion?p_IDHabitacionAsignada=" + _idAsignacionEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerAsignaciones();
            this.modalEliminarAsignacion.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  // openModalFormAsignacion(esEditable: boolean) {
  //   this.esEditable = esEditable;

  //   this.formGroupAgregarAsignaciones.reset({
  //     p_IDAsignacion: 0,
  //     p_nombreAsignacion: "",
  //     p_DescripcionAsignacion: "",
  //     p_Tel1: "",
  //     p_IDContactoResponsable: 0,
  //   });
  //   this.modalAgregarAsignacion = this.modalService.open(this.modalAddAsignacion, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  // }


  openDesactivar(idAsignacion, nombreAsignacion) {
    this._idAsignacionEliminar = idAsignacion;
    this._nombreAsignacion = nombreAsignacion;
    this.modalEliminarAsignacion = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Asignacion';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  // sumaHabitaciones() {
  //   let sumaHabitaciones = this.formGroupAgregarAsignaciones.value.p_HabitacionSencilla +
  //   this.formGroupAgregarAsignaciones.value.p_HabitacionDoble + this.formGroupAgregarAsignaciones.value.p_HabitacionTriple;
  //   this.formGroupAgregarAsignaciones.get('p_TotalHabitaciones').setValue(sumaHabitaciones);
  // }

  openModalAsignacion(esEditable: boolean) {
    this.esEditable = esEditable;

    this.formGroupAsignarHabitacion.reset({
      p_IDHotel: 0,
      p_TipoHabitacion: 0,
      p_Torre: "",
      p_Piso: "",
      p_IDContactoCongresista_1: 0,
      p_NombreCongresista_1: "",
      p_PorcentajePago_1: 0,
      p_IDContactoCongresista_2: 0,
      p_NombreCongresista_2: "",
      p_PorcentajePago_2: 0,
      p_IDContactoCongresista_3: 0,
      p_NombreCongresista_3: "",
      p_PorcentajePago_3: 0,
      p_IDContactoCongresista_4: 0,
      p_NombreCongresista_4: "",
      p_PorcentajePago_4: 0,
      p_ComentariosAsignacion: "",
    });
    this.modalSelHabitacion = this.modalService.open(this.modalSelccionarHabitacion, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  obtenerCatalogoGenerico(p_idCatGenerico) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoGenerico?p_idCatGenerico=" + p_idCatGenerico)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaTipoHabitacion = tempdate.response as Lista_catalogo_generico_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  obtenerLugaresHabitacion(_tipoHabitacion) {
    const _claveAsignacion = this.formGroupAsignarHabitacion.value.p_ClaveAsignacion;
    switch (_tipoHabitacion) {
      case "39":
        this.sencilla = true;
        this.doble = false;
        this.triple = false;
        this.cuadruple = false;
        this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + 'S');
        break;
      case "40":
        this.sencilla = true;
        this.doble = true;
        this.triple = false;
        this.cuadruple = false;
        this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + 'D');
        break;
      case "41":
        this.sencilla = true;
        this.doble = true;
        this.triple = true;
        this.cuadruple = false;
        this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + 'T');
        break;
      case "42":
        this.sencilla = true;
        this.doble = true;
        this.triple = true;
        this.cuadruple = true;
        this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + 'C');
        break;
    }
  }

  obtenerCatalogoContactos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoContactos?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaContactos = tempdate.response as Lista_catalogo_contactos_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  consultaHabitaciones(_idAsignacion) {
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetAsignacion_x_id?p_IDAsignacion=" + _idAsignacion)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Asignacion = tempdate.response as Asignacion_modelo_response;

            this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(this.Asignacion[0].idAsignacion);
  
          } else {
            this.listaAsignacion = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerPagos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetListaPagosHabitacionAsignada?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaPagos = [] = tempdate.response as Lista_pagos_modelo_response[];
            this.renderTabla();
          } else {
            this.listaPagos = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

getPagoCongresista(_espacioPagoCongresista, _idCongresista) {
  const _claveAsignacion = this.formGroupAsignarHabitacion.value.p_ClaveAsignacion;
  this.operacionRespuesta.EstaEjecutando = true;
  this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetPagos_x_id?p_IDContacto=" + _idCongresista)
    .subscribe(
      (tempdate) => {
        if (tempdate) {

          this.pagoCongresista = tempdate.response as Pago_modelo_response;

          switch (_espacioPagoCongresista) {
            case "p_IDContactoCongresista_1":
              this.formGroupAsignarHabitacion.get('p_NombreCongresista_1').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].nombreCompleto);
              this.formGroupAsignarHabitacion.get('p_PorcentajePago_1').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].importeRestante);
              this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + _idCongresista);
              break;
            case "p_IDContactoCongresista_2":
              this.formGroupAsignarHabitacion.get('p_NombreCongresista_2').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].nombreCompleto);
              this.formGroupAsignarHabitacion.get('p_PorcentajePago_2').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].importeRestante);
              this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + _idCongresista);
              break;
            case "p_IDContactoCongresista_3":
              this.formGroupAsignarHabitacion.get('p_NombreCongresista_3').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].nombreCompleto);
              this.formGroupAsignarHabitacion.get('p_PorcentajePago_3').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].importeRestante);
              this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + _idCongresista);
              break;
            case "p_IDContactoCongresista_4":
              this.formGroupAsignarHabitacion.get('p_NombreCongresista_4').setValue(this.pagoCongresista[this.pagoCongresista.length - 1].nombreCompleto);
              this.formGroupAsignarHabitacion.get('p_ClaveAsignacion').setValue(_claveAsignacion + _idCongresista);
              break;
          }
        } else {
          switch (_espacioPagoCongresista) {
            case "p_IDContactoCongresista_1":
              this.formGroupAsignarHabitacion.get('p_NombreCongresista_1').setValue("");
              this.formGroupAsignarHabitacion.get('p_PorcentajePago_1').setValue("");
              break;
            case "p_IDContactoCongresista_2":
              this.formGroupAsignarHabitacion.get('p_NombreCongresista_2').setValue("");
              this.formGroupAsignarHabitacion.get('p_PorcentajePago_2').setValue("");
              break;
            case "p_IDContactoCongresista_3":
              this.formGroupAsignarHabitacion.get('p_NombreCongresista_3').setValue("");
              this.formGroupAsignarHabitacion.get('p_PorcentajePago_3').setValue("");
              break;
            case "p_IDContactoCongresista_4":
              this.formGroupAsignarHabitacion.get('p_NombreCongresista_4').setValue("");
              break;
          }
        }
        this.operacionRespuesta.EstaEjecutando = false;      
      },
      async (err) => {
        this.operacionRespuesta.EstaEjecutando = false;
      }
    );


}


  }
