import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_perfiles_modelo_response } from 'src/model/Catalogos/Generales';
import { Lista_usuarios_sistema_modelo_response, Usuario_sistema_modelo_response } from 'src/model/Catalogos/Usuarios';
import { ServiceGenerico } from 'src/services/service-generico.service';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  providers: [ServiceGenerico]
})
export class UsuariosComponent implements OnInit {
  @ViewChild("modalAddUsuario", { static: false }) modalAddUsuario: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarUsuario: NgbModalRef;
  modalEliminarUsuario: NgbModalRef;

  formGroupAgregarUsuario: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  public usuarioId: string = "";

  private modelo_configuracion: ServiciosRutas;
  listaUsuarios: Lista_usuarios_sistema_modelo_response[];
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  _idUsuario: number;
  private _nombreUsuario: any;
  Usuario: Usuario_sistema_modelo_response;
  listaPerfiles: Lista_perfiles_modelo_response[];
  selectedCheckboxes: string[] = []; // Array para almacenar los valores seleccionados

  constructor(
    private auth: AuthGuard,
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerUsuarios();
    this.inicializarFormAgregarUsuario();
    this.getListaPerfiles();

  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerUsuarios() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/GetListaUsuariosSistema?p_estatus=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaUsuarios = [] = tempdate.response as Lista_usuarios_sistema_modelo_response[];
            this.renderTabla();
          } else {
            this.listaUsuarios = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarUsuario() {
    this.formGroupAgregarUsuario = this.fb.group({
      p_nombre: ["", Validators.required], 
      p_aPaterno: ["", Validators.required], 
      p_aMaterno: [""],
      p_Email1: ["", Validators.required],
      p_Tel1: [""],
      p_Contrasena: [""],
      p_Perfil: [0]
    });

  }

  agregarUsuario() {
    this.funcionalidad = 'Nuevo';
    this._idUsuario = 0;
    this.openModalFormUsuario(true);
  }

  GuardarUsuario() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarUsuario.value;
    model.p_idUsuario = this._idUsuario;
    
    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/PostUsuarioSistema")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerUsuarios();
              this.modalAgregarUsuario.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/PutUsuarioSistema")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerUsuarios();
              this.modalAgregarUsuario.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
    this.selectedCheckboxes = [];

  }

  verDetalleUsuario(idUsuario) {
    this.funcionalidad = 'Ver';
    this.esEditable = false;
    this.openModalFormUsuario(false);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/GetUsuarioSistema_x_id?p_idUsuario=" + idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Usuario = tempdate.response as Usuario_sistema_modelo_response;
            this.formGroupAgregarUsuario.value.p_nombre = this.Usuario[0].nombre;
            this.formGroupAgregarUsuario.value.p_aPaterno = this.Usuario[0].aPaterno;
            this.formGroupAgregarUsuario.value.p_aMaterno = this.Usuario[0].aMaterno;
            this.formGroupAgregarUsuario.value.p_Email1 = this.Usuario[0].email1;
            this.formGroupAgregarUsuario.value.p_Tel1 = this.Usuario[0].tel1;
            this.formGroupAgregarUsuario.value.p_Perfil = this.Usuario[0].idPerfil;
            this.formGroupAgregarUsuario.value.p_Realiza = this.Usuario[0].realiza;
            this.formGroupAgregarUsuario.value.p_Revisa = this.Usuario[0].revisa;
            this.formGroupAgregarUsuario.value.p_Autoriza = this.Usuario[0].autoriza;
            this.formGroupAgregarUsuario.patchValue(this.formGroupAgregarUsuario.value);
          } else {
            this.listaUsuarios = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );



  }

  editarUsuario(idUsuario) {
    this.funcionalidad = 'Editar';
    this._idUsuario = idUsuario;
    this.openModalFormUsuario(true);
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/GetUsuarioSistema_x_id?p_IDUsuario=" + idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Usuario = tempdate.response as Usuario_sistema_modelo_response;
            this.formGroupAgregarUsuario.value.p_nombre = this.Usuario[0].nombre;
            this.formGroupAgregarUsuario.value.p_aPaterno = this.Usuario[0].aPaterno;
            this.formGroupAgregarUsuario.value.p_aMaterno = this.Usuario[0].aMaterno;
            this.formGroupAgregarUsuario.value.p_Email1 = this.Usuario[0].email1;
            this.formGroupAgregarUsuario.value.p_Tel1 = this.Usuario[0].tel1;
            this.formGroupAgregarUsuario.value.p_Perfil = this.Usuario[0].idPerfil;
            this.formGroupAgregarUsuario.value.p_Realiza = this.Usuario[0].realiza;
            this.formGroupAgregarUsuario.value.p_Revisa = this.Usuario[0].revisa;
            this.formGroupAgregarUsuario.value.p_Autoriza = this.Usuario[0].autoriza;
            this.formGroupAgregarUsuario.patchValue(this.formGroupAgregarUsuario.value);
          } else {
            this.listaUsuarios = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

  }

  eliminarUsuario(idUsuario) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/UsuariosSistema/DeleteUsuarioSistema?p_IDUsuario=" + idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerUsuarios();
            this.modalEliminarUsuario.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  openModalFormUsuario(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarUsuario.reset({ p_nombre: '', p_aPaterno: '', p_aMaterno: '', p_Email1: '', p_Tel1: '', p_Contrasena: '',
           p_Perfil: 0, p_Realiza: 0, p_Revisa: 0, p_Autoriza: 0 });

    this.modalAgregarUsuario = this.modalService.open(this.modalAddUsuario, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }


  openDesactivar(idUsuario, nombreCat) {
    this._idUsuario = idUsuario;
    this._nombreUsuario = nombreCat;
    this.modalEliminarUsuario = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Usuarios';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  getListaPerfiles() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Generales/GetListaPerfiles?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaPerfiles = tempdate.response as Lista_perfiles_modelo_response[];
          } 
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

}
