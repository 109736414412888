import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_congreso_response, Lista_catalogo_contactos_grupo_response, Lista_catalogo_contactos_response, Lista_catalogo_generico_response, Lista_catalogo_grupo_response } from 'src/model/Catalogos/Catalogos';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { AuthIdentity } from '../guards/AuthIdentity';
import { Lista_remesa_excel_modelo_response, Lista_remesa_sistema_modelo_response, Lista_urls_remesa, Pago_modelo_response, Plan_vigente_modelo_response, Remesa_modelo_response, Remesa_pdf_modelo_response, Ultimo_pago_modelo_response } from 'src/model/Catalogos/Renesas';
import { HttpClient } from '@angular/common/http';
import * as html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-remesas',
  templateUrl: './remesas.component.html',
  styleUrls: ['./remesas.component.css'],
  providers: [ServiceGenerico]
})
export class RemesasComponent implements OnInit {
  @Input() esValidacionRemesas: boolean;
  @Input() modulo: string;
  @ViewChild("modalAddRemesa", { static: false }) modalAddRemesa: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  @ViewChild("contentRemesaPDF", { static: false }) ModalRemesaPDF: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarRemesa: NgbModalRef;
  modalEliminarRemesa: NgbModalRef;
  modalRemesaPDF: NgbModalRef;

  formGroupAgregarRemesa: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  listaRemesas: Lista_remesa_sistema_modelo_response[];
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  private _nombreUsuario: any;
  Remesa: Remesa_modelo_response;
  titulo: string;
  listaGrupos: Lista_catalogo_grupo_response[];
  bnd_p_LlevaServicio: boolean = false;
  bnd_p_ParejaEnElMovimiento: boolean = false;
  bnd_p_TieneDiscapacidad: boolean = false;
  idUsuario: number;
  _idContactoEliminar: number;
  listaContactosGrupos: Lista_catalogo_contactos_grupo_response[];
  listaCongresos: Lista_catalogo_congreso_response[];
  listaMoneda: Lista_catalogo_generico_response[];
  listaContactos: Lista_catalogo_contactos_response[];
  depositos: any[] = [];
  importeTotal: number = 0;
  seHaAgregadoDeposito: boolean = false;
  _idRemesaEliminar: any;
  _nombreRemesa: any;
  formData: FormData;
  importeTransaccionActual: number = 0;
  importeTransaccionNuevoDeposito: number = 0;
  pasaElLimite: boolean;
  RemesaPdf: Remesa_pdf_modelo_response;
  listaRemesasExcel: Lista_remesa_excel_modelo_response[];
  esCortesia: boolean = false;
  archivos: File[] = []; // Lista de archivos antes de agregarlos a FormData
  urls: string[];
  PlanVigente: Plan_vigente_modelo_response;
  UltimoPagoContacto: Ultimo_pago_modelo_response;
  private _idMoneda: number = 1;
  noCoincideDeno: boolean = false;

  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
    this.idUsuario = usuarioActual.IDUsuario;
    this.inicializaTabla();
    this.obtenerRemesa();
    this.inicializarFormAgregarRemesa();
    if (this.esValidacionRemesas) {
      this.titulo = "Validar Remesas";
    }else {
      this.titulo = "Remesas";
    }

    if (this.modulo === 'Cortesia') {
      this.titulo = "Cortesías";
      this.esCortesia = true;
    }
    this.obtenerCatalogoGrupo();
    this.obtenerCatalogoCongreso();
    this.obtenerCatalogoGenerico(5); //Moneda
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  obtenerRemesa() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetListaRemesas?p_estado=1&p_IDUsuario=" + this.idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaRemesas = [] = tempdate.response as Lista_remesa_sistema_modelo_response[];
            this.renderTabla();
          } else {
            this.listaRemesas = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarRemesa() {


    this.formGroupAgregarRemesa = this.fb.group({
      p_IDRemesa: [0],
      p_IDGrupo: [0],
      p_IDCongreso: [0],
      p_IDContactoResponsable: [0],
      p_Recibi: [""],
      p_FechaRegistro: [""],
      p_FechaTransaccion: [""],
      p_Referencia: [""],
      p_ImporteTransaccion: [0,[Validators.pattern(/^[0-9.]*$/)]],
      p_IDContactoCongresista: [0],
      p_NombreCongresista: [0],
      p_IDMoneda: [0],
      p_ImporteIndividual: [0],
      p_Moneda: [""],
      p_Recibe: [0],
      p_Entrega: [0],
      p_NombreCongreso: [0],
      p_RutaComprobante: [""],
      p_SumaImporteTotal: [0],
      p_SumaImporteIndividual: [0],
      p_ComentariosRemesa:[""],
      p_PlanDisponible:[""],
      p_CostoPlan:[""],
      p_Restante:[0]
    });

  }

  agregarRemesa() {
    this.funcionalidad = 'Nuevo';
    this.formData = new FormData();
    this.archivos = [];

    if (this.esCortesia) {
      const fechaHoy = new Date();
      // const fechaNacimiento = new Date(this.Remesa[0].fechaTransaccion);
      const fechaCorta = fechaHoy.toISOString().split('T')[0];
      this.formGroupAgregarRemesa.get('p_FechaTransaccion').setValue(fechaCorta);
      this.formGroupAgregarRemesa.get('p_FechaTransaccion').disable();
      this.formGroupAgregarRemesa.get('p_Referencia').setValue('Cortesía');
      this.formGroupAgregarRemesa.get('p_Referencia').disable();
      this.formGroupAgregarRemesa.get('p_ImporteTransaccion').setValue(1);
      this.formGroupAgregarRemesa.get('p_ImporteIndividual').setValue(1);
      this.formGroupAgregarRemesa.get('p_ComentariosRemesa').setValue('Remesa de cortesía');
      this.actualizaSumaImportes();
    }
    this.getPlanVigente();

    this.openModalFormUsuario(true);

  }

  GuardarRemesa() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarRemesa.value;
    model.p_Depositos = this.depositos;
    model.p_IDEntrega = this.idUsuario;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Remesas/PostRemesa")
        .subscribe(
          (tempdate) => {
            if (this.esCortesia) {
            this.formGroupAgregarRemesa.get('p_IDRemesa').setValue(tempdate.response[0].success);
              this.ActualizarRemesa('Confirmar');
            } else {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerRemesa();
              this.modalAgregarRemesa.close();
            }

            this.subirArchivos(tempdate.response[0].success.toString());

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Remesas/PutRemesa")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.subirArchivos(this.formGroupAgregarRemesa.value.p_IDRemesa.toString());
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerRemesa();
              this.modalAgregarRemesa.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
      }
  }

  editarverDetalleRemesa(idRemesa, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormUsuario(false);
    } else if (opcion === 'Editar') {
      this.funcionalidad = 'Editar';
      this.openModalFormUsuario(true);
      this.esEditable = true;
    } else if (opcion === 'Validar'){
      this.funcionalidad = 'Validar';
      this.openModalFormUsuario(false);
    }
    this.getPlanVigente();
    this.ObtenerComprobantesRemesa(idRemesa)
    this.formData = new FormData();
    this.archivos = [];
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetRemesa_x_id?p_IDRemesa=" + idRemesa)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Remesa = tempdate.response as Remesa_modelo_response;
            this.formGroupAgregarRemesa.get('p_IDRemesa').setValue(this.Remesa[0].idRemesa);
            this.formGroupAgregarRemesa.get('p_IDGrupo').setValue(this.Remesa[0].idGrupo);
            this.obtenerCatalogoContactosGrupo()
            this.formGroupAgregarRemesa.get('p_IDCongreso').setValue(this.Remesa[0].idCongreso);
            this.formGroupAgregarRemesa.get('p_IDContactoResponsable').setValue(this.Remesa[0].idContactoResponsable);
            this.formGroupAgregarRemesa.get('p_Recibi').setValue(this.Remesa[0].recibi);
            this.formGroupAgregarRemesa.get('p_NombreCongreso').setValue(this.Remesa[0].nombreCongreso);
            this.formGroupAgregarRemesa.get('p_RutaComprobante').setValue(this.Remesa[0].rutaComprobante);
            this.formGroupAgregarRemesa.get('p_SumaImporteTotal').setValue(this.Remesa[0].sumaImporteIndividual);
            this.formGroupAgregarRemesa.get('p_SumaImporteIndividual').setValue(this.Remesa[0].sumaImporteIndividual);
            this.formGroupAgregarRemesa.get('p_Recibe').setValue(this.Remesa[0].recibe);
            this.formGroupAgregarRemesa.get('p_Entrega').setValue(this.Remesa[0].entrega);
            this.formGroupAgregarRemesa.get('p_ComentariosRemesa').setValue(this.Remesa[0].comentarios);

            const fechaNacimiento = new Date(this.Remesa[0].fechaTransaccion);
            const fechaCorta = fechaNacimiento.toISOString().split('T')[0];

            for (const remesa of tempdate.response) {

            const p_FechaTransaccion = fechaCorta;
            const p_Referencia = remesa.referencia;
            const p_ImporteTransaccion = remesa.importeTransaccion;
            const p_IDMoneda = remesa.idMoneda.toString();
            const p_IDContactoCongresista = remesa.idContacto;
            const p_NombreCongresista = remesa.nombreCongresista;
            const p_ImporteIndividual = remesa.importeIndividual;

            const nuevoDeposito = {
              p_FechaTransaccion,
              p_Referencia,
              p_ImporteTransaccion,
              p_IDMoneda,
              p_IDContactoCongresista,
              p_NombreCongresista,
              p_ImporteIndividual
            }

            this.depositos.push(nuevoDeposito);
          }

          } else {
            this.listaRemesas = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );



  }

  eliminarRemesa(_idContactoEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Remesas/DeleteRemesa?p_IDRemesa=" + _idContactoEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerRemesa();
            this.modalEliminarRemesa.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  openModalFormUsuario(esEditable: boolean) {
    this.importeTransaccionActual = 0;
    this.importeTransaccionNuevoDeposito = 0;

    this.esEditable = esEditable;
    const fechaHoy = new Date();
    const fechaHoySinHora = new Date(fechaHoy.getFullYear(), fechaHoy.getMonth(), fechaHoy.getDate());
    const fechaHoyISO = fechaHoySinHora.toISOString().slice(0, 10); // Solo obtenemos la parte de la fecha en formato ISO

    this.formGroupAgregarRemesa.reset({ p_IDRemesa: 0,
      p_IDGrupo: 0,
      p_IDCongreso: 0,
      p_IDContactoResponsable: 0,
      p_Recibi: "",
      p_FechaRegistro: fechaHoyISO,
      p_FechaTransaccion: "",
      p_Referencia: "",
      p_ImporteTransaccion: 0,
      p_IDContactoCongresista: 0,
      p_NombreCongresista: 0,
      p_ImporteTotal: 0,
      p_IDMoneda: 0,
      p_ImporteIndividual: 0,
      p_Moneda: "",
      p_Recibe: 0,
      p_Entrega: 0,
      p_NombreCongreso: 0,
      p_RutaComprobante: "",
      p_PlanDisponible: "",
      p_CostoPlan: "",
      p_Restante: 0,
      p_SumaImporteIndividual: 0,
      p_SumaImporteTotal: 0
    });
    this.importeTotal = 0;
    this.depositos = [];
    this.modalAgregarRemesa = this.modalService.open(this.modalAddRemesa, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'xl' });
  }


  openDesactivar(idRemesa, nombreRemesa) {
    this._idRemesaEliminar = idRemesa;
    this._nombreRemesa = nombreRemesa;
    this.modalEliminarRemesa = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Remesa';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  obtenerCatalogoGenerico(p_idCatGenerico) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoGenerico?p_idCatGenerico=" + p_idCatGenerico)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            switch (p_idCatGenerico) {
              case 5:
                this.listaMoneda = tempdate.response as Lista_catalogo_generico_response[];
                break;
            }
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  ObtenerComprobantesRemesa(_idRemesa) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Remesas/ObtenerComprobantesRemesa?idRemesa=" + _idRemesa)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.urls = tempdate as string[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerCatalogoGrupo() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoGrupo?p_estado=1&p_IDUsuario="+ this.idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaGrupos = tempdate.response as Lista_catalogo_grupo_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerCatalogoContactosGrupo() {
    let p_IDGrupo = this.formGroupAgregarRemesa.value.p_IDGrupo;
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoContactosGrupo?p_estado=1&p_IDGrupo=" + p_IDGrupo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaContactosGrupos = tempdate.response as Lista_catalogo_contactos_grupo_response[];
            this.obtenerCatalogoContactos()

          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerCatalogoContactos() {
    this.operacionRespuesta.EstaEjecutando = true;
    let p_IDGrupo = this.formGroupAgregarRemesa.value.p_IDGrupo;

    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoContactos?p_estado=1&p_Filtro=Remesa&p_IDContacto=" + this.idUsuario +"&p_IDGrupo=" + p_IDGrupo)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaContactos = tempdate.response as Lista_catalogo_contactos_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  obtenerCatalogoCongreso() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogoCongreso?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCongresos = tempdate.response as Lista_catalogo_congreso_response[];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  completarCampo(opcion, _idContacto) {
    // const _idContacto = event.target.value;

    if (opcion == 'p_NombreCongresista') {
      this.formGroupAgregarRemesa.get('p_IDContactoCongresista').setValue(_idContacto);
    } else if (opcion == 'p_IDContactoCongresista') {
      this.formGroupAgregarRemesa.get('p_NombreCongresista').setValue(_idContacto);
      this.consultaPagoContacto(_idContacto);
    }
  }

  getPlanVigente() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetPlanVigente?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.PlanVigente = tempdate.response as Plan_vigente_modelo_response;
            this.formGroupAgregarRemesa.get('p_PlanDisponible').setValue(this.PlanVigente[0].nombrePlan);
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  getCostoDenominacion(idMoneda) {
    // this.noCoincideDeno = false;
    switch (idMoneda) {
      case "18":
        this.formGroupAgregarRemesa.get('p_CostoPlan').setValue(this.PlanVigente[0].costoPesos);
        break;
      case "19":
        this.formGroupAgregarRemesa.get('p_CostoPlan').setValue(this.PlanVigente[0].costoDolares);
        break;
      case "20":
        this.formGroupAgregarRemesa.get('p_CostoPlan').setValue(this.PlanVigente[0].costoEuros);
        break;
    }
    this.completarCampo('p_IDContactoCongresista', 0);
    this.completarCampo('p_NombreCongresista', 0);
    this.formGroupAgregarRemesa.get('p_Restante').setValue(0);

  }

  consultaPagoContacto(idContacto) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetUltimoPago_x_id?p_IDContacto=" + idContacto)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.UltimoPagoContacto = tempdate.response as Ultimo_pago_modelo_response;
            this.formGroupAgregarRemesa.get('p_Restante').setValue(this.UltimoPagoContacto[0].importeRestante);
            this._idMoneda = this.UltimoPagoContacto[0].idMoneda;
            if (this.formGroupAgregarRemesa.get('p_IDMoneda').value != this._idMoneda) {
              this.noCoincideDeno = true;
            } else {
              this.noCoincideDeno = false;
            }
          }
           else {
            this.noCoincideDeno = false;
            this.formGroupAgregarRemesa.get('p_Restante').setValue(0);
          }
          if (this.esCortesia) {
            this.formGroupAgregarRemesa.get('p_Restante').setValue(0);
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  agregarCampos() {
    const importeTotal = parseFloat(this.formGroupAgregarRemesa.get('p_SumaImporteTotal').value);
    this.importeTransaccionActual += parseFloat(this.formGroupAgregarRemesa.get('p_ImporteIndividual').value);
    const costoPlan = parseFloat(this.formGroupAgregarRemesa.get('p_CostoPlan').value);
    const restante = parseFloat(this.formGroupAgregarRemesa.get('p_Restante').value);

    if (this.importeTransaccionActual <= importeTotal &&
      this.importeTransaccionActual !== 0 &&
      this.importeTransaccionActual <= costoPlan
    ) {

      const p_FechaTransaccion = this.formGroupAgregarRemesa.get('p_FechaTransaccion').value;
      const p_Referencia = this.formGroupAgregarRemesa.get('p_Referencia').value;
      const p_ImporteTransaccion = this.formGroupAgregarRemesa.get('p_ImporteTransaccion').value;
      const p_IDMoneda = this.formGroupAgregarRemesa.get('p_IDMoneda').value;
      const p_IDContactoCongresista = this.formGroupAgregarRemesa.get('p_IDContactoCongresista').value;
      const p_NombreCongresista = this.formGroupAgregarRemesa.get('p_NombreCongresista').value;
      const p_ImporteIndividual = this.formGroupAgregarRemesa.get('p_ImporteIndividual').value;

      // this.importeTotal += parseFloat(p_ImporteTransaccion);
      this.formGroupAgregarRemesa.get('p_SumaImporteIndividual').setValue(this.importeTransaccionActual);

      const nuevoDeposito = {
        p_FechaTransaccion,
        p_Referencia,
        p_ImporteTransaccion,
        p_IDMoneda,
        p_IDContactoCongresista,
        p_NombreCongresista,
        p_ImporteIndividual
      }

      // Verifica si alguno de los campos está vacío
      if (!p_FechaTransaccion || !p_Referencia || !p_ImporteTransaccion || !p_IDContactoCongresista || !p_NombreCongresista) {
        return;
      } else {
        this.seHaAgregadoDeposito = true;
        this.depositos.push(nuevoDeposito);
      }

      // Limpiar los campos del depósito actual
      this.formGroupAgregarRemesa.get('p_IDContactoCongresista').reset();
      this.formGroupAgregarRemesa.get('p_NombreCongresista').reset();
      this.formGroupAgregarRemesa.get('p_ImporteIndividual').reset();
      this.pasaElLimite = false;

    } else {
      this.importeTransaccionActual -= parseFloat(this.formGroupAgregarRemesa.get('p_ImporteIndividual').value);

      this.pasaElLimite = true;
    }




  }

  agregarNuevoDepo() {
    this.importeTransaccionNuevoDeposito += parseFloat(this.formGroupAgregarRemesa.get('p_ImporteTransaccion').value);

    this.formGroupAgregarRemesa.get('p_FechaTransaccion').reset();
    this.formGroupAgregarRemesa.get('p_Referencia').reset();
    this.formGroupAgregarRemesa.get('p_ImporteTransaccion').reset();
    this.formGroupAgregarRemesa.get('p_IDMoneda').reset();
    this.formGroupAgregarRemesa.get('p_IDContactoCongresista').reset();
    this.formGroupAgregarRemesa.get('p_NombreCongresista').reset();
    this.formGroupAgregarRemesa.get('p_ImporteIndividual').reset();


  }


  eliminarDeposito(index: number) {
    const deposito = this.depositos[index];
    if (this.depositos.length > 1) {
      if (deposito) {
        this.importeTransaccionActual -= parseFloat(deposito.p_ImporteIndividual);
  
        this.importeTotal -= parseFloat(deposito.p_ImporteIndividual);
  
        this.formGroupAgregarRemesa.get('p_SumaImporteIndividual').setValue(this.importeTotal);
      }
    } else {
      this.depositos = [];
      this.formGroupAgregarRemesa.get('p_SumaImporteIndividual').setValue(0);
      this.importeTransaccionActual = 0;
   }
    

    this.depositos.splice(index, 1);

  }

  agregarComprobante(event: any) {
    const files = event.target.files;
    this.formData = new FormData();

    if (files) {
      // Contadores para rastrear la cantidad de archivos y las posiciones en formData
      let archivosAgregados = 0;
      let formDataPosicion = 0;
      let archivosEliminados = 0; // Contador de archivos eliminados

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSizeInMB = file.size / (1024 * 1024); // Convertir el tamaño a MB

        if (fileSizeInMB > 5) {
          this.openMensajes("Un archivo es demasiado grande (máximo 5 MB): " + file.name, true, '');
          event.target.value = null; // Borra el input
          archivosEliminados++;
        } else if (archivosAgregados < 5 && formDataPosicion < 5) {
          // Agregar el archivo a formData solo si no se ha superado el límite de archivos y posiciones
          this.formData.append('archivos', file);
          this.archivos.push(file); // Agrega el archivo a la lista de archivos
          archivosAgregados++;
          formDataPosicion++;
        } else {
          this.openMensajes("Se supera el límite de archivos a cargar", true, '');
          event.target.value = null; // Borra el input si se supera el límite de archivos
          archivosEliminados++;
        }
      }

      if (archivosEliminados === files.length) {
        // Todos los archivos se eliminaron, puedes mostrar un mensaje de error si lo deseas.
        this.openMensajes("Todos los archivos son demasiado grandes o excede el límite de archivos", true, '');
      }
    }

  }


  subirArchivos(_IDRemesa) {
    const formData = new FormData();

    for (const archivo of this.archivos) {
      formData.append("p_FormData", archivo);
    }

    formData.append("p_IDRemesa", _IDRemesa.toString());

    this.http
      .post(this.modelo_configuracion.serviciosOperaciones + "/Remesas/subir-comprobante", formData)
      .subscribe(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.error(error);
        }
      );
  }

    actualizaSumaImportes(){

    this.formGroupAgregarRemesa.get('p_SumaImporteTotal').setValue(this.importeTransaccionNuevoDeposito + parseFloat(this.formGroupAgregarRemesa.get('p_ImporteTransaccion').value));
    }

    openPDF(idRemesa, nombreCongresista) {
      this.operacionRespuesta.EstaEjecutando = true;
      this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetRemesa_x_id_pdf?p_IDRemesa=" + idRemesa)
        .subscribe(
          (tempdate) => {
            if (tempdate) {
              this.RemesaPdf = tempdate.response as Remesa_pdf_modelo_response;
            } else {
              this.listaRemesas = [];
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async (err) => {
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );

      this.modalRemesaPDF = this.modalService.open(this.ModalRemesaPDF, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'xl' });
    }

    generarPDF(_idRemesa) {
      const options = {
        filename: 'Remesa_' + _idRemesa + '.pdf',
        image: { type: 'jpeg', quality: 1.0 }, // Establece una calidad de imagen máxima (rango: 0.0 - 1.0)
        html2canvas: { scale: 2 }, // Configuración de rendering de HTML2Canvas
        jsPDF: { orientation: 'portrait' }
      };

      const element = document.getElementById('htmlPrint');

      html2pdf()
        .set(options)
        .from(element)
        .save();
    }

    cerrarModalPDF() {
      this.modalRemesaPDF.close();
    }

    ActualizarRemesa(_opcion: string) {
      let model = {
        p_opcion: _opcion,
        p_IDRemesa: this.formGroupAgregarRemesa.value.p_IDRemesa,
        p_ComentariosRemesa: this.formGroupAgregarRemesa.value.p_ComentariosRemesa
      };

      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Remesas/ConfirmarPagoRemesa")
        .subscribe(
          (tempdate) => {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerRemesa();
            this.modalAgregarRemesa.close();

            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }

    getListExcel() {
      this.operacionRespuesta.EstaEjecutando = true;
      this.services
        .HttpGet(
          this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetListaRemesasExcel?p_estado=1&p_IDUsuario=" + this.idUsuario)
        .subscribe(
          (tempdate) => {
            if (tempdate) {
              this.listaRemesasExcel = [] = tempdate.response as Lista_remesa_excel_modelo_response[];

              this.exportToExcel();
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
    exportToExcel(): void {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.page.len(-1).draw(); // -1 significa mostrar todos los registros

        const fechaHoraLegible = this.getFecha();


       // Crear una matriz de objetos con la fecha formateada
    const dataExcel = this.listaRemesasExcel.map(remesa => {
      const fechaRegistro = new Date(remesa.fechaRegistro);
      const fechaTransaccion = new Date(remesa.fechaTransaccion);
      return {
        'ID Remesa': remesa.idRemesa,
        'Fecha Registro': fechaRegistro.toLocaleDateString(), // Formatear la fecha
        'N. Contacto Responsable': remesa.idContactoResponsable,
        'Nombre Congreso': remesa.nombreCongreso,
        'Fecha Transacción': fechaTransaccion.toLocaleDateString(),
        'Referencia': remesa.referencia,
        'Moneda': remesa.moneda,
        'Importe Transacción': remesa.importeTransaccion,
        'Numero Congresista': remesa.numeroCongresista,
        'Nombre Congresista': remesa.nombreCongresista,
        'Importe Individual': remesa.importeIndividual,
        'Suma Importe Total': remesa.sumaImporteTotal,
        'Suma Importe Individual': remesa.sumaImporteIndividual,
        'Estado Remesa': remesa.estadoRemesa,
        'Comentarios': remesa.comentarios
      };
    });

        // const element = document.getElementById('RemesasList');
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExcel);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(data, 'lista_remesas_' + fechaHoraLegible + '.xlsx');

        this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
          instancia.page.len(10).draw(); // Vuelve a establecer la paginación a 10 registros por página
        });

      });
    }

    private getFecha() {
      const tiempoEnMilisegundos = new Date().getTime();

      const fechaYHora = new Date(tiempoEnMilisegundos);

      const anio = fechaYHora.getFullYear();
      const mes = fechaYHora.getMonth() + 1;
      const dia = fechaYHora.getDate();
      const horas = fechaYHora.getHours();
      const minutos = fechaYHora.getMinutes();
      const segundos = fechaYHora.getSeconds();

      const fechaHoraLegible = `${anio}_${mes}_${dia}_${horas}_${minutos}_${segundos}`;
      return fechaHoraLegible;
    }

  }
