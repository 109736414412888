import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { AuthGuard } from 'src/app/guards/AuthGuard';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_generico_response, Lista_catalogo_grupo_response, Lista_catalogos_genericos_response, Lista_sub_catalogos_genericos_response } from 'src/model/Catalogos/Catalogos';
import { Lista_congresista_sistema_modelo_response, Usuario_congresista_modelo_response } from 'src/model/Catalogos/Congresistas';
import { ServiceGenerico } from 'src/services/service-generico.service';
// import { AuthIdentity } from '../guards/AuthIdentity';

@Component({
  selector: 'app-genericos',
  templateUrl: './genericos.component.html',
  styleUrls: ['./genericos.component.css'],
  providers: [ServiceGenerico]
})
export class GenericosComponent implements OnInit {
  @ViewChild("modalAddCatalogo", { static: false }) modalAddCatalogo: TemplateRef<any>;
  @ViewChild("contentDesactivar", { static: false }) ModalDesactivar: TemplateRef<any>;
  @ViewChild("contentDesactivarOpcion", { static: false }) ModalDesactivarOpcion: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalAgregarCatalogo: NgbModalRef;
  modalEliminarCatalogo: NgbModalRef;

  formGroupAgregarCatalogo: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  titulo: string;
  listaCatalogos: Lista_catalogos_genericos_response[];
  Catalogo: Lista_catalogos_genericos_response;
  _nombreCatalogo: string;
  _idCatalogoEliminar: number;
  formGroupAgregarSubCatalogo: FormGroup;
  opcionesCatalogo: Lista_sub_catalogos_genericos_response[] = [];
  modalEliminarCatalogoOpcion: NgbModalRef;
  _idSubCatalogoEliminar: number;
  _nombreSubCatalogo: string;
  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    this.inicializaTabla();
    this.obtenerCatalogos();
    this.inicializarFormAgregarCatalogo();
    this.inicializarFormAgregarSubCatalogo();

    this.titulo = "Catálogo Genérico";

  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  obtenerCatalogos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetListaCatalogosGenericos?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCatalogos = [] = tempdate.response as Lista_catalogos_genericos_response[];
            this.renderTabla();
          } else {
            this.listaCatalogos = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  inicializarFormAgregarCatalogo() {
    this.formGroupAgregarCatalogo = this.fb.group({
      p_IDCatGenerico: [0],
      p_Nombre: ["", Validators.required],
      p_Descripcion: ["", Validators.required]
    });
  }
  inicializarFormAgregarSubCatalogo() {
    this.formGroupAgregarSubCatalogo = this.fb.group({
      p_IDCatGenerico: [0],
      p_IDSubCatGenerico: [0],
      p_NombreOpcion: ["", Validators.required],
    });
  }

  agregarCatalogo() {
    this.funcionalidad = 'Nuevo';
    this.openModalFormCatalogo(true);
  }



  GuardarCatalogo() {
    this.operacionRespuesta.EstaEjecutando = true;
    let model = this.formGroupAgregarCatalogo.value;

    if (this.funcionalidad == 'Nuevo') {
      this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostCatalogoGenerico")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCatalogos();
              this.modalAgregarCatalogo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    } else {
      this.services.HttpPut(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PutCatalogoGenerico")
        .subscribe(
          (tempdate) => {
            if (tempdate.response[0].success == 1) {
              this.openMensajes(tempdate.response[0].msg, false, '');
              this.obtenerCatalogos();
              this.modalAgregarCatalogo.close();
            } else {
              this.openMensajes(tempdate.response[0].msg, true, '');
            }
            this.operacionRespuesta.EstaEjecutando = false;
          },
          async () => {
            this.openMensajes("No se pudo realizar la acción", true, '');
            this.operacionRespuesta.EstaEjecutando = false;
          }
        );
    }
  }

  editarverDetalleCatalogo(idCatGenerico, opcion) {
    if (opcion === 'Ver') {
      this.funcionalidad = 'Ver';
      this.esEditable = false;
      this.openModalFormCatalogo(false);
    } else if (opcion === 'Editar') {
      this.funcionalidad = 'Editar';
      this.openModalFormCatalogo(true);
      this.esEditable = true;
    } else if (opcion === 'Agregar') {
      this.funcionalidad = 'Agregar';
      this.esEditable = false;
      this.getSubCatalogos(idCatGenerico);
      this.openModalFormCatalogo(false);
    }
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetCatalogo_x_id?p_IDCatGenerico=" + idCatGenerico)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.Catalogo = tempdate.response as Lista_catalogos_genericos_response;
            this.formGroupAgregarCatalogo.get('p_IDCatGenerico').setValue(this.Catalogo[0].idCatGenerico);
            this.formGroupAgregarCatalogo.get('p_Nombre').setValue(this.Catalogo[0].nombre);
            this.formGroupAgregarCatalogo.get('p_Descripcion').setValue(this.Catalogo[0].descripcion);
          } else {
            // this.listaCatalogo = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  getSubCatalogos(idCatGenerico) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.formGroupAgregarSubCatalogo.get('p_IDCatGenerico').setValue(idCatGenerico);

    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/GetSubCatalogos?p_IDCatGenerico=" + idCatGenerico)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.opcionesCatalogo = tempdate.response as Lista_sub_catalogos_genericos_response[];
          } else {
            this.opcionesCatalogo = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

  }
  eliminarCatalogo(_idCatGenericoEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteCatalogo?p_IDCatGenerico=" + _idCatGenericoEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.obtenerCatalogos();
            this.modalEliminarCatalogo.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  openModalFormCatalogo(esEditable: boolean) {
    this.esEditable = esEditable;
    this.formGroupAgregarCatalogo.reset({ p_IDCatGenerico: 0, p_Nombre: '', p_Descripcion: '' });
    this.modalAgregarCatalogo = this.modalService.open(this.modalAddCatalogo, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  openDesactivar(idCatGenerico, nombreCat) {
    this._idCatalogoEliminar = idCatGenerico;
    this._nombreCatalogo = nombreCat;
    this.modalEliminarCatalogo = this.modalService.open(this.ModalDesactivar, { ariaLabelledBy: 'modal-basic-title' });
  }

  openDesactivarOpcion(idSubCatGenerico, nombreSubCat) {
    this._idSubCatalogoEliminar = idSubCatGenerico;
    this._nombreSubCatalogo = nombreSubCat;
    this.modalEliminarCatalogoOpcion = this.modalService.open(this.ModalDesactivarOpcion, { ariaLabelledBy: 'modal-basic-title' });
  }

  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Catálogo';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  AgregarOpcion() {
    let model = this.formGroupAgregarSubCatalogo.value;

    this.services.HttpPost(model, this.modelo_configuracion.serviciosOperaciones + "/Catalogos/PostSubCatalogoGenerico")
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.opcionesCatalogo = [];
            this.formGroupAgregarSubCatalogo.get('p_NombreOpcion').reset();

            this.getSubCatalogos(this.formGroupAgregarSubCatalogo.value.p_IDCatGenerico);
            // this.modalAgregarCatalogo.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }

  eliminarSubCatalogo(_idSubCatalogoEliminar) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpDelete(this.modelo_configuracion.serviciosOperaciones + "/Catalogos/DeleteSubCatalogo?p_IDSubCatGenerico=" + _idSubCatalogoEliminar)
      .subscribe(
        (tempdate) => {
          if (tempdate.response[0].success == 1) {
            this.openMensajes(tempdate.response[0].msg, false, '');
            this.getSubCatalogos(this.formGroupAgregarSubCatalogo.value.p_IDCatGenerico);
            this.modalEliminarCatalogoOpcion.close();
          } else {
            this.openMensajes(tempdate.response[0].msg, true, '');
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.openMensajes("No se pudo realizar la acción", true, '');
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
}
