import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ThemeConstants } from 'src/app/@espire/shared/config/theme-constant';
import { RespuestaGenerica } from 'src/app/generales/RespuestaGenerica';
import { ServiciosRutas } from 'src/app/generales/ServiciosRutas';
import { ModuloModalMensajeComponent } from 'src/app/shared/modulo-modal-mensaje/modulo-modal-mensaje.component';
import { Lista_catalogo_generico_response, Lista_catalogo_grupo_response } from 'src/model/Catalogos/Catalogos';
import { Lista_congresista_excel_modelo_response, Lista_congresista_sistema_modelo_response, Usuario_congresista_modelo_response, Usuario_congresista_pdf_modelo_response } from 'src/model/Catalogos/Congresistas';
import { Lista_pagos_modelo_response, Pago_modelo_response } from 'src/model/Catalogos/Renesas';
import { ServiceGenerico } from 'src/services/service-generico.service';
// import { AuthIdentity } from '../guards/AuthIdentity';
import * as html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { AuthIdentity } from 'src/app/guards/AuthIdentity';
@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css'],
  providers: [ServiceGenerico]
})
export class PagosComponent implements OnInit {
  @ViewChild("contentPDF", { static: false }) ModalPDF: TemplateRef<any>;
  modalrefMsg: NgbModalRef;
  modalPDF: NgbModalRef;

  formGroupAgregarCongresista: FormGroup;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;

  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  esEditable: boolean = true;
  funcionalidad: string;
  private _nombreUsuario: any;
  titulo: string;
  idUsuario: number;
  listaServicios: Lista_catalogo_generico_response[];
  CongresistaPdf: Usuario_congresista_pdf_modelo_response;
  listaPagos: Lista_pagos_modelo_response[];
  RemesaPdf: Pago_modelo_response;
  listaCongresistasExcel: Lista_congresista_excel_modelo_response[];

  constructor(
    private themeConstants: ThemeConstants,
    private services: ServiceGenerico,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.modelo_configuracion = new ServiciosRutas();
    this.operacionRespuesta = new RespuestaGenerica();
  }

  ngOnInit() {
    var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
    this.idUsuario = usuarioActual.IDUsuario;
    this.inicializaTabla();
    this.obtenerPagos();
    this.titulo = "Pagos"
  }

  inicializaTabla() {
    this.dtOptions = this.themeConstants.dtOptions;
    this.dtTrigger = new Subject();
  }

  renderTabla() {
    if ("dtInstance" in this.dtElement) {
      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  
  obtenerPagos() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetListaPagos?p_estado=1")
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaPagos = [] = tempdate.response as Lista_pagos_modelo_response[];
            this.renderTabla();
          } else {
            this.listaPagos = [];
            this.renderTabla();
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
 
  openMensajes(Mensaje: string, Error: boolean, titulo: string) {
    this.modalrefMsg = this.modalService.open(ModuloModalMensajeComponent, { ariaLabelledBy: "modal-basic-title" });
    this.modalrefMsg.componentInstance.mensajesExito = [];
    this.modalrefMsg.componentInstance.mensajesError = [];
    this.modalrefMsg.componentInstance.mensajeTitulo = 'Congresista';

    if (Error) {
      this.modalrefMsg.componentInstance.showErrors = true;
      this.modalrefMsg.componentInstance.mensajesError.push(Mensaje);
    } else {
      this.modalrefMsg.componentInstance.showExitos = true;
      this.modalrefMsg.componentInstance.mensajesExito.push(Mensaje);
    }
  }

  openPDF(idPago) {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services.HttpGet(this.modelo_configuracion.serviciosOperaciones + "/Remesas/GetPagos_x_id?p_IDContacto=" + idPago)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.RemesaPdf = tempdate.response as Pago_modelo_response;
          } else {
            this.listaPagos = [];
          }
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async (err) => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );

    this.modalPDF = this.modalService.open(this.ModalPDF, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
  }

  generarPDF(_idRemesa) {
    const options = {
      filename: 'Bono_' + _idRemesa + '.pdf',
      image: { type: 'jpeg', quality: 1.0 }, // Establece una calidad de imagen máxima (rango: 0.0 - 1.0)
      html2canvas: { scale: 2 }, // Configuración de rendering de HTML2Canvas
      jsPDF: { orientation: 'portrait' }
    };

    const element = document.getElementById('htmlPrint');

    html2pdf()
      .set(options)
      .from(element)
      .save();
  }

  cerrarModalPDF() {
    this.modalPDF.close();
  }


  getListExcel() {
    this.operacionRespuesta.EstaEjecutando = true;
    this.services
      .HttpGet(
        this.modelo_configuracion.serviciosOperaciones + "/Congresistas/GetListaCongresistasExcel?p_estado=1&p_IDUsuario="+this.idUsuario)
      .subscribe(
        (tempdate) => {
          if (tempdate) {
            this.listaCongresistasExcel = [] = tempdate.response as Lista_congresista_excel_modelo_response[];
            this.exportToExcel();
          } 
          this.operacionRespuesta.EstaEjecutando = false;
        },
        async () => {
          this.operacionRespuesta.EstaEjecutando = false;
        }
      );
  }
  exportToExcel(): void {
    this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
      instancia.page.len(-1).draw(); // -1 significa mostrar todos los registros

      // Supongamos que tempdate.response es el arreglo de datos original
      const dataExcel = this.listaCongresistasExcel.map(item => {
      const fechaRegistro = new Date(item.fechaRegistro);
      const fechaNacimiento = new Date(item.fechaNacimiento);

          return {
            'N. Congresista': item.idContacto,
            'Nombre Grupo': item.nombreGrupo,
            'Apellido Paterno': item.aPaterno, // Tanto aPaterno como aMaterno se almacenan en aPaterno
            'Apellido Materno': item.aMaterno, // Tanto aPaterno como aMaterno se almacenan en aPaterno
            'Nombre': item.nombre,
            'Alias': item.alias,
            'Tiempo de Militancia': item.tiempoMilitancia,
            'Teléfono': item.tel1,
            'Email': item.email1,
            'Fecha de Nacimiento': fechaNacimiento.toLocaleDateString(),
            'Edad': item.edad,
            'Género': item.genero,
            'Teléfono Grupo': item.telGrupo,
            'Orientación Sexual': item.orientacion,
            'Primer Congreso': item.primerCongreso,
            'Responsable Grupo': item.responsableGrupo,
            'Servicio en el Congreso': item.llevaServicio,
            'Está en Casa Hogar': item.estaEnCasaHogar,
            'Pareja dentro del Movimiento': item.parejaEnElMovimiento,
            'Hospedarse con Pareja': item.hospedarseConPareja,
            'Nombre de Pareja': item.contactoParejaNombre,
            'Apellido Paterno de Pareja': item.contactoParejaPaterno,
            'Apellido Materno de Pareja': item.contactoParejaMaterno,
            'Grupo de Pareja': item.grupoPareja,
            'Discapacidad': item.discapacidad,
            'Nombre de Contacto con Discapacidad': item.contactoCDiscapNombre,
            'Apellido Paterno de Contacto con Discapacidad': item.contactoCDiscapPaterno,
            'Apellido Materno de Contacto con Discapacidad': item.contactoCDiscapMaterno,
            'Grupo Contacto': item.grupoDiscapacidad,
            'Contacto de Emergencia': item.contactoEmergencia,
            'Teléfono de Contacto de Emergencia': item.telefonoContactoEmer,
            'Fecha de Registro': fechaRegistro.toLocaleDateString(),
            'Plan': item.nombrePlan,
            'Estado Pago': item.estadoPago,
            'Ultimo Pago Generado': (item.ultimoTotalPagado).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),
            'Monto Restante': (item.importeRestante).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),
            'Porcentaje de Pago': item.ultimoPorcentajePagado + "%",
        };
      });

      
      const fechaHoraLegible = this.getFecha();
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExcel);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      FileSaver.saveAs(data, 'lista_pagos_' + fechaHoraLegible + '.xlsx');

      this.dtElement.dtInstance.then((instancia: DataTables.Api) => {
        instancia.page.len(10).draw(); // Vuelve a establecer la paginación a 10 registros por página
      });

    });

  }

  private getFecha() {
    const tiempoEnMilisegundos = new Date().getTime();

    const fechaYHora = new Date(tiempoEnMilisegundos);

    const anio = fechaYHora.getFullYear();
    const mes = fechaYHora.getMonth() + 1;
    const dia = fechaYHora.getDate();
    const horas = fechaYHora.getHours();
    const minutos = fechaYHora.getMinutes();
    const segundos = fechaYHora.getSeconds();

    const fechaHoraLegible = `${anio}_${mes}_${dia}_${horas}_${minutos}_${segundos}`;
    return fechaHoraLegible;

  }

}
