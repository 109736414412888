import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../guards/AuthGuard';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { LocalStorageService } from 'src/services/local-storage.service';
import { AuthIdentity } from '../guards/AuthIdentity';
import { ServiceGenerico } from 'src/services/service-generico.service';
import { ServiciosRutas } from '../generales/ServiciosRutas';
import { Menu_principal_response, Sub_menu_principal_response } from 'src/model/Catalogos/Generales';
import { RespuestaGenerica } from '../generales/RespuestaGenerica';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [AuthGuard, ServiceGenerico]
})
export class MenuComponent implements OnInit {
  private modelo_configuracion: ServiciosRutas;
  public operacionRespuesta: RespuestaGenerica;
  public isAuth: boolean;
  public banner:string;
  opcionMenu: number = 0;
  nombreUsuario: any;
  idUsuario: number;
  idPerfil: number;
  listaMenus: Menu_principal_response[];
  listaSubMenus: Sub_menu_principal_response[];
  subModulosUnicos: any[] = [];
  constructor(
    private auth: AuthGuard,
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private services: ServiceGenerico,
  ) {
    this.modelo_configuracion = new ServiciosRutas();

   }

  ngOnInit() {
    this.isAuth = this.auth.canActivate();
    if (this.isAuth === false) {
        this.cerrarSesion();
    } else {
      this.authService.estatusActualDelUsuarioEnSesion$().subscribe((isAuth) => {
        this.isAuth = isAuth;
        this.abrirMenu('0');
      });
  
      var usuarioActual = AuthIdentity.ObtenerUsuarioSesion();
      this.nombreUsuario = usuarioActual.Nombre + ' ' + usuarioActual.APaterno + ' ' + usuarioActual.AMaterno;
      this.idUsuario = usuarioActual.IDUsuario;
      this.idPerfil = usuarioActual.IDPerfil;

      this.CargaMenuPrincipal(this.idPerfil);
    }
  }

  public abrirMenu(menu: string) {
    this.opcionMenu

    switch (menu) {
      case 'ListadoCongresistas':
        this.opcionMenu = 1;
        break;
      case 'ListadoRemesas':
        this.opcionMenu = 2;
        break;
      case 'AsignarHabitacion':
        this.opcionMenu = 3;
        break;
      case 'ListadoOrdenes':
        this.opcionMenu = 4;
        break;
      case 'ListadoServicios':
        this.opcionMenu = 5;
        break;
      case 'Usuarios':
        this.opcionMenu = 6;
        break;
      case 'General':
        this.opcionMenu = 7;
        break;
      case 'CatalogosGenericos':
        this.opcionMenu = 8;
        break;
      case 'Perfiles':
        this.opcionMenu = 9;
        break;
      case 'Grupos':
        this.opcionMenu = 10;
        break;
      case 'Zonas':
        this.opcionMenu = 11;
        break;
      case 'Areas':
        this.opcionMenu = 12;
        break;
      case 'Planes':
        this.opcionMenu = 13;
        break;
      case 'Congresos':
        this.opcionMenu = 14;
        break;
      case 'Hoteles':
        this.opcionMenu = 15;
        break;
      case 'ValidarRemesas':
          this.opcionMenu = 19;
          break;
      case 'Pagos':
          this.opcionMenu = 20;
          break;
      case 'AutorizarOrden':
          this.opcionMenu = 21;
          break;
      case 'Cortesias':
          this.opcionMenu = 22;
          break;
      case 'Configuracion':
          this.opcionMenu = 23;
          break;
      default:
        this.opcionMenu = 0; // Opcional: asignar un valor por defecto si no coincide con ninguna opción.
        break;
    }

    
  }

  public cerrarSesion(): void {
    this.auth.logOut();
    this.authService.EsEstaAutenticado(false);
    this.localStorageService.clearToken();
    this.router.navigate(["/login"]);
  }

// Modifica la función CargaMenuPrincipal para que filtre los submódulos
CargaMenuPrincipal(_idPerfil) {
  // this.operacionRespuesta.EstaEjecutando = true;
  this.services.HttpGet(
    this.modelo_configuracion.serviciosOperaciones + "/Generales/GetMenuPrincipal?p_IDPerfil=" + _idPerfil)
    .subscribe(
      async (tempdate) => {
        if (tempdate) {
          this.listaMenus = tempdate.response as Menu_principal_response[];
          // Llama a getSubmodulos para obtener los submódulos de cada menú
        await this.listaMenus.forEach(item => this.getSubmodulos(item.idModulo, _idPerfil));
        }
        // this.operacionRespuesta.EstaEjecutando = false;
      },
      async () => {
        // this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}

// Modifica la función getSubmodulos para almacenar los submódulos en un objeto
getSubmodulos(_idModulo, _idPerfil) {
  // this.operacionRespuesta.EstaEjecutando = true;
  this.services.HttpGet(
    this.modelo_configuracion.serviciosOperaciones + "/Generales/GetSubMenuPrincipal?p_IDModulo=" + _idModulo + "&p_IDPerfil=" + _idPerfil)
    .subscribe(
      (tempdate) => {
        if (tempdate) {
          // Almacena los submódulos en un objeto usando _idModulo como clave
          this.subModulosUnicos[_idModulo] = tempdate.response as Sub_menu_principal_response[];
        }
        // this.operacionRespuesta.EstaEjecutando = false;
      },
      async () => {
        // this.operacionRespuesta.EstaEjecutando = false;
      }
    );
}

}
